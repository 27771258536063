import {
	request,
	get,
	post,
	patch
} from './request.js'

let api = {
	getserviceSurplus: 'portal/rest/1.0/wallet/warn', //获取短信、语音余量
	getHistory1: 'portal/rest/1.0/order', //获取充值记录
	getHistory2: 'portal/rest/1.0/history/warn/expenses', //获取消费记录
	getserviceItem: 'portal/rest/1.0/item/warn', //根据套餐类型获取套餐规格
	getserviceItemIotCard: 'portal/rest/1.0/item/iot-card', //获取物联网卡套餐规格
	getIotCardData: 'portal/rest/1.0/iotcard', //获取物联网卡套餐
	iot: 'portal/rest/1.0/payment/item/iot', //物联网卡支付
	paymentWarn: `portal/rest/1.0/payment/item/warn`, //报警套餐的充值
	paymentStatus: `portal/rest/1.0/payment/status`, //获取订单状态
}

// 获取余量
function getserviceSurplus(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token')
		}
	}
	return get(api.getserviceSurplus, params, configs)
}

// 获取余量
function getIotCardData(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token')
		}
	}
	return get(api.getIotCardData, params, configs)
}

// 获取充值记录
function getHistory1(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token')
		}
	}
	return get(api.getHistory1, params, configs)
}


// 获取消费记录
function getHistory2(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token')
		}
	}
	return get(api.getHistory2, params, configs)
}


//根据套餐类型获取套餐规格
function getserviceItem(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token')
		}
	}
	return get(api.getserviceItem, params, configs)
}

//根据套餐类型获取套餐规格
function getserviceItemIotCard(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token')
		}
	}
	return get(api.getserviceItemIotCard, params, configs)
}
//物联网卡套餐充值
function iot(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
			'content-type': 'application/json'
		}
	}
	return post(api.iot, params, configs)
}

//短信、语音套餐充值
function paymentWarn(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
			'content-type': 'application/json'
		}
	}
	return post(api.paymentWarn, params, configs)
}
//获取支付状态
function paymentStatus(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token')
		}
	}
	return get(api.paymentStatus, params, configs)
}
export default {
	getserviceSurplus,
	getHistory1,
	getHistory2,
	getserviceItem,
	getserviceItemIotCard,
	getIotCardData,
	iot,
	paymentWarn,
	paymentStatus
}
