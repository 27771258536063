<template>
  <div>
    <Breadcrumb :first="bread[0]" :second="bread[1]"></Breadcrumb>
    <el-row :gutter="10"
      ><el-col :span="12">
        <el-row :gutter="10">
          <el-col>
            <el-card class="shadow-sm">
              <el-row>
                <el-col>
                  <h5>设备状态</h5>
                </el-col>
              </el-row>
              <el-row class="content_margin_top">
                <el-col :span="12" class="text-center">
                  <el-row>
                    <el-col><label class="small_title">设备总数</label></el-col>
                    <el-col
                      ><label class="small_content">{{
                        !dataDevice.deviceNum ? 0 : dataDevice.deviceNum
                      }}</label>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12" class="text-center">
                  <el-row>
                    <el-col><label class="small_title">在线设备</label></el-col>
                    <el-col>
                      <label
                        :class="
                          dataDevice.online == dataDevice.deviceNum
                            ? 'small_content text-success'
                            : 'small_content text-danger'
                        "
                        >{{ !dataDevice.online ? 0 : dataDevice.online }}</label
                      >
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12" class="text-center">
                  <el-row>
                    <el-col><label class="small_title">离线设备</label></el-col>
                    <el-col
                      ><label
                        :class="
                          dataDevice.offline > 0
                            ? 'small_content text-danger'
                            : 'small_content'
                        "
                        >{{
                          !dataDevice.offline ? 0 : dataDevice.offline
                        }}</label
                      >
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12" class="text-center">
                  <el-row>
                    <el-col><label class="small_title">报警设备</label></el-col>
                    <el-col>
                      <label
                        :class="
                          dataDevice.warnNum === '0'
                            ? 'small_content text-danger'
                            : 'small_content'
                        "
                      >
                        {{
                          !dataDevice.warnNum ? 0 : dataDevice.warnNum
                        }}</label
                      >
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24">
            <el-card class="shadow-sm">
              <el-row>
                <el-col>
                  <h5>套餐余量</h5>
                </el-col>
              </el-row>
              <el-row class="content_margin_top">
                <el-col :span="12" class="text-center">
                  <el-row>
                    <el-col><label class="small_title">短信</label></el-col>
                    <el-col
                      ><label class="small_content">{{
                        !serviceData.sms ? 0 : serviceData.sms
                      }}</label>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12" class="text-center">
                  <el-row>
                    <el-col><label class="small_title">语音</label></el-col>
                    <el-col
                      ><label class="small_content"
                        >{{ !serviceData.tel ? 0 : serviceData.tel }}
                      </label>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24">
            <el-card class="shadow-sm">
              <el-row>
                <el-col>
                  <h5>套餐使用</h5>
                </el-col>
              </el-row>
              <el-row class="content_margin_top">
                <el-col :span="12" class="text-center">
                  <el-row>
                    <el-col><label class="small_title">短信</label></el-col>
                    <el-col
                      ><label class="small_content"
                        ><!-- {{ !income.activeUsers ? 0 : income.activeUsers }}-->0</label
                      >
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12" class="text-center">
                  <el-row>
                    <el-col><label class="small_title">语音</label></el-col>
                    <el-col
                      ><label class="small_content"
                        ><!-- {{ !income.paidUsers ? 0 : income.paidUsers }} -->0</label
                      >
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <!-- <el-col :span="24">
            <el-card class="shadow-sm">
              <el-row>
                <el-col>
                  <h5>实时设备在线率</h5>
                </el-col>
                <el-col>
                  <div
                    id="charts1"
                    ref="chart"
                    style="height: 400px; width: 100%;"
                  ></div>
                </el-col>
              </el-row>
            </el-card>
          </el-col> -->
          <!-- <el-col :span="24">
            <el-card class="shadow-sm">
              <el-row>
                <el-col>
                  <h5>7天报警统计</h5>
                </el-col>
                <el-col>
                  <div id="charts2" style="height: 400px; width: 100%;"></div>
                </el-col>
              </el-row>
            </el-card>
          </el-col> -->
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-row>
          <el-col :span="24">
            <el-card class="shadow-sm">
              <el-row>
                <el-col>
                  <h5>设备状态占比</h5>
                </el-col>
                <el-col>
                  <div id="charts3" style="width: 100%;height: 440px;"></div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-dialog title="平台公告内容" :visible.sync="dialogVisible" width="40%">
      <div v-html="content"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
import service from "../../assets/api/service.js";
import home from "../../assets/api/home.js";
import * as echarts from "echarts";
import Breadcrumb from "../components/breadCrumb.vue";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      bread: ["首页", ""],
      dataDevice: {
        deviceNum: 0,
        warnNum: 0,
        offline: 0,
        online: 0,
      },
      serviceData: {
        sms: 0,
        tel: 0,
        iotCardBalanceList: [],
      },
      tableData1: {},
      tableData2: {},
      content: "",
      dialogVisible: false,
      query: {
        index: 1,
        size: 10,
      },
      myChart: "",
      option: {
        legend: {
          data: ["今日", "昨日"],
          icon: "rect",
          top: 22,
          // right: 24,
          itemGap: 15,
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            color: "rgba(0,0,0,0.87)",
          },
        },
        tooltip: {
          trigger: "axis",
        },

        color: ["#289df5", "#fbc01b", "#ff5050"],
        grid: {
          left: 24,
          right: "6%",
          bottom: "30",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            alignWithLabel: true,
          },

          axisLabel: {
            interval: 0,
            rotate: -20,
            textStyle: {
              color: "#00c5d7",
            },
          },
          name: "(时间)",
          nameTextStyle: {
            padding: [24, 0, 0, 0],
            color: "#00c5d7",
          },
          nameGap: 0,
          data: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
          ],
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            length: 0, // 刻度线的长度
          },
          splitLine: {
            lineStyle: {
              color: "rgba(105,105,105,.5)",
              width: 1,
              type: "dashed",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#a3a4b2",
            },
          },
        },
        series: [],
      },
      option3: {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            center: ["50%", "50%"],
            data: [],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              normal: {
                label: {
                  show: true,
                  // formatter: "{b} : {c} ({d}%)", // 带当前图例名 + 百分比
                  formatter: "{b} : {d}%", // 带当前图例名 + 百分比
                  // formatter: "{d}%", // 只要百分比
                },
                labelLine: { show: true },
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getuserDeviceStatus();
    // this.getdeviceStatus();
    // this.loadBarCharts()
    // this.loadCharts1()
    window.addEventListener("resize", this.windowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResize);
  },
  computed: {
    reversedMessage() {
      return this.dataDevice.online / this.dataDevice.total;
    },
  },
  filters: {
    // utc 转  本地
    utcMoment(d) {
      if (!d) {
        return "--";
      }
      var i = -(new Date().getTimezoneOffset() / 60);
      var len = new Date(d).getTime();
      var utcTime = len;
      return moment(new Date(utcTime + 3600000 * i)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
  },
  methods: {
    //获取套餐剩余量
    async getserviceSurplus() {
      await service.getserviceSurplus().then(
        (res) => {
          if (res.code === 200) {
            this.serviceData = res.data;
          } else {
            this.$message.error("获取套餐剩余量失败");
            return;
          }
        },
        () => {
          this.$message.error("获取套餐剩余量失败");
          return;
        }
      );
    },
    //获取设备总数，在线设备，离线设备，报警设备
    async getdeviceStatus() {
      await home.getdeviceStatus().then((res) => {
        if (res === undefined) {
          this.$router.push("/Login");
        } else {
          if (res.code === 200) {
            if (res.data) {
              this.dataDevice.deviceNum = res.data.length;
              let offlineSum = res.data.filter((item) => {
                return item.online == false;
              });
              let warnNum = res.data.filter((item) => {
                return item.warn == true;
              });
              if (offlineSum) {
                this.dataDevice.offline = offlineSum.length;
              }
              if (warnNum) {
                this.dataDevice.warnNum = warnNum.length;
              }
              this.dataDevice.online = Number(
                this.dataDevice.deviceNum - this.dataDevice.offline
              );
            }
            this.getserviceSurplus();
          }
        }
      });
    },
    async getuserDeviceStatus() {
      await home.getuserDeviceStatus().then((res) => {
        if (res === undefined) {
          this.$router.push("/Login");
        } else {
          if (res.code === 200) {
            if (res.data) {
              this.dataDevice.deviceNum = res.data.totalNum;
              this.dataDevice.offline = res.data.offlineNum;
              this.dataDevice.warnNum = res.data.warnNum;
              this.dataDevice.online = res.data.onlineNum;
              this.option3.series[0].data = [
                { value: res.data.onlineNum, name: "在线设备" },
                { value: res.data.offlineNum, name: "离线设备" },
              ];
            }
            this.getserviceSurplus();
            this.loadPieCharts3();
          }
        }
      });
    },
    //实时使用数据曲线图
    async loadCharts1() {
      await statistics.usageSeries().then((res) => {
        if (res.code !== 200) {
          this.$message.error("获取实时使用数据失败");
          return;
        } else {
          var obj = res.data; //定义对象
          var arr = []; //定义数组
          for (var i in obj) {
            arr.push(obj[i]);
          }
          arr.map((item, index) => {
            this.option.series.push({
              name: "",
              type: "line",
              smooth: true,
              symbol: "circle",
              symbolSize: 6,
              itemStyle: {
                normal: {
                  color: "",
                  borderColor: "#ffffff",
                  areaStyle: {
                    type: "default",
                    opacity: 0.1,
                  },
                },
              },
              data: [],
            });
            item.map((item) => {
              item.date = item.date;
              this.option.series[index].data.push(item.num);
            });
          });
          if (arr[0][23].num > arr[1][23].num) {
            this.option.series[0].name = "昨日";
            this.option.series[1].name = "今日";
          } else {
            this.option.series[0].name = "今日";
            this.option.series[1].name = "昨日";
          }
        }
      });
      let myChart = echarts.init(document.getElementById("charts1"));
      myChart.resize();
      myChart.setOption(this.option);
    },
    async loadBarCharts() {
      let dataList = [];
      let day = [];
      let income = [];
      await statistics.getWeekIncome().then(
        (res) => {
          if (res.code !== 200) {
            this.$message.error("获取7天收入数据失败");
            return;
          } else {
            dataList = res.data;

            for (let i in dataList) {
              day.push(dataList[i].dateTime);
              income.push(dataList[i].income);
            }
          }
        },
        () => {
          this.$message.error("获取7天收入数据失败");
        }
      );
      var option = {
        title: {
          text: "",
          subtext: "",
        },
        tooltip: {
          trigger: "axis",
        },
        label: {
          show: true,
          position: "top",
          fontSize: "14",
          position: [20, -20],
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            data: day,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "#f05053",
                },
                {
                  offset: 0,
                  color: "#FFC371",
                },
              ]),
            },
            showBackground: true,
            type: "bar",
            data: income,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      let myChart2 = echarts.init(document.getElementById("charts2"));
      myChart2.resize();
      myChart2.setOption(option);
    },
    loadPieCharts3() {
      let myChart3 = echarts.init(document.getElementById("charts3"));
      myChart3.resize();
      myChart3.setOption(this.option3);
    },
    windowResize() {
      this.loadPieCharts3();
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.el-tag {
  cursor: pointer;
  margin-right: 10px;
}

.noticeEmpty {
  color: #999999;
  font-size: 14px !important;
}
</style>
