<template>
	<div>
		<Breadcrumb :first='bread[0]' :second="bread[1]"></Breadcrumb>
		<el-card class="shadow-sm">
			<el-row>
				<el-col>
					<el-select v-model="selectValue" placeholder="请选择设备" @change="handleSelect" size="small">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-col>
				<el-col class="content_margin_top">
					<el-table ref="dragRegTable" row-key="addr" :data="tableData" size="mini" style="width: 500px;" stripe border
						:header-cell-style="{ 'background': '#f1f1f1','text-align':'center'}"
						:cell-style="{'text-align':'center'}">
						<el-table-column prop="name" align="center" label="资源名称"></el-table-column>
						<el-table-column prop="addr" align="center" label="资源地址"></el-table-column>
						<el-table-column align="center" label="移动" width="140">
							<template slot-scope="{}">
								<i class="el-icon-rank" style="font-size:26px;cursor: pointer;"></i>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import Sortable from "sortablejs";
	import device from '../../assets/api/device.js'
	import Breadcrumb from '../components/breadCrumb.vue'
	export default {
		components: {
			Breadcrumb
		},
		data() {
			return {
				bread: ['设备管理', '资源排序'],
				tableData: [],
				listQuery: {
					index: 1,
					size: 10
				},
				total: 0,
				sortable: null,
				oldList: [],
				newList: [],
				options: [],
				selectValue: ""
			};
		},
		mounted() {
			this.getdeviceByGroup()
		},
		methods: {
			//获取资源
			async getdeviceByGroup() {
				await device.getdeviceByGroup().then(res => {
					let data = res.data.map(item => {
						return {
							value: item.id,
							label: item.name
						};
					});
					this.options = data;
					this.selectValue = data[0].value;
					this.handleSelect();
				})
			},
			//通过设备获取资源
			async handleSelect() {
				await device.getresourceByDevice({
					deviceId: this.selectValue
				}).then(res => {
					if (res.code === 200) {
						this.tableData = res.data;
						if (res.data) {
							let data = res.data.map(item => {
								return item.addr;
							});
							this.newList = data;
						}
						this.$nextTick(() => {
							this.setSort();
						});
					} else {
						this.$message.error('获取资源数据失败')
						return
					}
				}, () => {
					this.$message.error('获取资源数据失败')
					return
				});
			},
			//排序效果
			setSort() {
				const el = this.$refs.dragRegTable.$el.querySelectorAll(
					".el-table__body-wrapper > table > tbody"
				)[0];
				this.sortable = Sortable.create(el, {
					ghostClass: "sortable-ghost",
					setData: function(dataTransfer) {
						dataTransfer.setData("Text", "");
					},

					onEnd: evt => {
						const targetRow = this.tableData.splice(evt.oldIndex, 1)[0];
						this.tableData.splice(evt.newIndex, 0, targetRow);
						const tempIndex = this.newList.splice(evt.oldIndex, 1)[0];
						this.newList.splice(evt.newIndex, 0, tempIndex);
						this.handleRegSort();
					}
				});
			},
			//排序功能
			async handleRegSort() {
				let data = [];
				this.newList.forEach((item, index) => {
					data.push({
						regAddr: item,
						sort: index
					});
				});
				await device.sortResource({
					deviceId: this.selectValue,
					sortList: data
				}).then(res => {
					this.$message.success("分组排序成功!");
					this.handleSelect();
				});
			}
		}
	};
</script>

<style lang="less">

</style>
