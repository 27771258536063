<template>
	<div>
		<Breadcrumb :first='bread[0]' :second="bread[1]"></Breadcrumb>
		<el-card class="shadow-sm">
			<el-row>
				<el-col>
					<el-select size="small" placeholder="请选择设备" v-model="query.deviceId" @change="getMessageData">
						<el-option v-for="item in deviceList" :value="item.id" :label="item.name"></el-option>
					</el-select>
					<el-date-picker v-model="value1" type="datetimerange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" size="small" style="margin-left: 10px;" :picker-options="pickerOptions"
						value-format="yyyy-MM-dd HH:mm:ss" @change="getMessageData">
					</el-date-picker>
				</el-col>
				<el-col class="content_margin_top">
					<el-table :data="tableData" size="mini" stripe border
						:header-cell-style="{ 'background': '#f1f1f1','text-align':'center'}"
						:cell-style="{'text-align':'center'}">
						<el-table-column prop="deviceId" label="设备id">
						</el-table-column>
						<el-table-column prop="deviceName" label="设备名称">
						</el-table-column>
						<el-table-column prop="regAddr" label="地址">
						</el-table-column>
						<el-table-column prop="regName" label="地址名称">
						</el-table-column>
						<el-table-column prop="data" label="地址数据">
						</el-table-column>
						<el-table-column label="是否报警">
							<template slot-scope="scope">
								<el-tag type="success" size="mini" v-if="scope.row.isWarn===false">报警解除</el-tag>
								<el-tag type="danger" size="mini" v-if="scope.row.isWarn===true">报警触发</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="time" label="时间">
						</el-table-column>
					</el-table>
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="query.index" :page-sizes="[30, 50, 70, 90]" :page-size="query.size"
						layout="total, sizes, prev, pager, next, jumper" :total="query.total">
					</el-pagination>
				</el-col>
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import device from '../../assets/api/device.js'
	import message from '../../assets/api/message.js'
	import Breadcrumb from '../components/breadCrumb.vue'
	export default {
		components: {
			Breadcrumb
		},
		data() {
			return {
				bread: ['消息服务', ''],
				value1: '',
				tableData: [],
				deviceList: [],
				query: {
					total:0,
					deviceId: '',
					startTime: '',
					endTime: '',
					index: 1,
					size: 20
				},
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
			}
		},
		mounted() {
			this.getdeviceByGroup()
			var day = new Date();
			var Year = 0;
			var Month = 0;
			var Day = 0;
			var CurrentDate = "";

			Year = day.getFullYear(); //ie火狐下都可以
			Month = day.getMonth() + 1;
			Day = day.getDate();
			CurrentDate += Year + "-";
			if (Month >= 10) {
				CurrentDate += Month + "-";
			} else {
				CurrentDate += "0" + Month + "-";
			}
			if (Day >= 10) {
				CurrentDate += Day;
			} else {
				CurrentDate += "0" + Day;
			}

			let nowdate = CurrentDate
			this.query.startTime = nowdate + ' 00:00:00'
			this.query.endTime = nowdate + ' 23:59:59'
			this.value1 = [this.query.startTime, this.query.endTime]
		},
		methods: {
			//获取设备信息
			async getdeviceByGroup() {
				await device.getdeviceByGroup().then(res => {
					if (res.code !== 200) {
						this.$message.error('获取设备数据失败')
						return
					} else {
						this.deviceList = res.data
						this.query.deviceId = res.data[0].id
						this.getMessageData()
					}
				}, () => {
					this.$message.error('建立连接失败')
				})
			},
			//获取报警消息数据列表
			async getMessageData() {
				this.query.startTime = this.value1[0]
				this.query.endTime = this.value1[1]
				await message.getMessageData(this.query).then(res => {
					if (res.code === 200) {
						this.tableData = res.data
						this.query.total=res.total
					} else {
						this.$message.error('获取报警消息数据失败')
						return
					}
				}, () => {
					this.$message.error('获取报警消息数据失败')
					return
				})
			},
			handleSizeChange(val) {
				this.query.size = val
				this.getMessageData()
			},
			handleCurrentChange(val) {
				this.query.index = val
				this.getMessageData()
			}
		}
	}
</script>

<style>
</style>
