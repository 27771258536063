<template>
	<div>
		<Breadcrumb :first='bread[0]' :second="bread[1]"></Breadcrumb>
		<el-card class="shadow-sm">
			<el-button size="mini" type="primary" @click="addVideo = true">添加摄像头</el-button>
		</el-card>
		
		<el-card class="shadow-sm">
			<el-table :data="tableData" size="mini" stripe border style="width: 1200px;"
				:header-cell-style="{ 'background': '#F3F3F3','text-align':'center'}"
				:cell-style="{'text-align':'center'}">
				<el-table-column label="摄像头封面">
					<template slot-scope="scope">
						<img v-if="scope.row.img===null||scope.row.img===''" src="../../assets/image/camera.png" alt="" style="width: 100px;height: 100px;border-radius: 10px;">
						<img v-else :src="scope.row.img" alt="" style="width: 100px;height: 100px;border-radius: 10px;">
					</template>
				</el-table-column>
				<el-table-column prop="title" label="摄像头名称" width="140px"></el-table-column>
				<el-table-column prop="deviceId" label="设备id" width="140px"></el-table-column>
				<el-table-column prop="deviceName" label="设备名称" width="140px"></el-table-column>
				<el-table-column label="播放">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" icon="el-icon-video-play" @click="handleVideo(scope.row)"></el-button>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="warning" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
						<el-button type="danger" size="mini" @click="displayDeleteDialog(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="listQuery.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="listQuery.pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="listQuery.total">
			</el-pagination>
		</el-card>

		<el-dialog title="添加摄像头" :visible.sync="addVideo" width="500px">
			<el-form ref="addModelRef" :model="addModalVideo" label-width="80px" :rules="addModelRules">
				<el-form-item label="标题" prop="title">
					<el-input v-model="addModalVideo.title" />
				</el-form-item>
				<el-form-item label="地址" prop="url">
					<el-input v-model="addModalVideo.url" />
				</el-form-item>
				<el-form-item label="设备" prop="deviceId">
					<el-select placeholder="请选择设备" v-model="addModalVideo.deviceId">
						<el-option v-for="item in deviceList" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="上传封面" prop="img">
					<el-upload action ref="uploadInsert" :data="aliyunOssToken" :http-request="uploadImg" list-type="picture" :limit="1"
						:on-exceed="handleOssList">
						<el-button class="avatar-uploader-icon" type="primary" plain>选择封面</el-button>
					</el-upload>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handelAddVideo('addModelRef')">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="编辑摄像头" :visible.sync="updateVideo" width="500px">
			<el-form ref="updateModelRef" :model="updateModalVideo" label-width="80px" :rules="addModelRules">
				<el-form-item label="标题" prop="title">
					<el-input v-model="updateModalVideo.title" />
				</el-form-item>
				<el-form-item label="地址" prop="url">
					<el-input v-model="updateModalVideo.url" />
				</el-form-item>
				<el-form-item label="设备" prop="deviceId">
					<el-select placeholder="请选择设备" v-model="updateModalVideo.deviceId">
						<el-option v-for="item in deviceList" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="上传封面" prop="img">
					<el-upload action ref="uploadUpdate" :data="aliyunOssToken" :http-request="uploadImg" list-type="picture" :limit="1"
						:on-exceed="handleOssList">
						<el-button class="avatar-uploader-icon" type="primary" plain>选择封面</el-button>
					</el-upload>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handelUpdateVideo('updateModelRef')">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="播放" :visible.sync="playDialogVisible" width="1300px" @close="closeVideo" @open="showVideo=true" @opened="videPlay()">
			<el-row>
				<el-col :span="12">
					<h5>{{cameraName}}</h5>
				</el-col>
				<el-col :span="12" style="padding-left: 20px;">
					<h5>{{deviceName}}</h5>
				</el-col>
				<el-col :span="12" class="content_margin_top" style="height: 400px !important;">
					<video id="my-video" ref="videoRef" class="video-js vjs-default-skin vjs-big-play-centered vjs-fluid" v-if="showVideo===true" preload="auto" :autoplay="true" muted="muted" controls style="width: 100%;height: auto;">
					    <source :src="videoUrl" type="application/x-mpegURL rtmp/hd"> 
					</video>
				</el-col>
				<el-col :span="12" style="padding-left: 20px;" class="content_margin_top">
					<el-table :data="$store.state.tableDataResource" size="mini" stripe border
						:header-cell-style="{ 'background': '#f1f1f1','text-align':'center'}"
						:cell-style="{'text-align':'center'}">
						<el-table-column prop="addr" label="地址" width="100px"></el-table-column>
						<el-table-column prop="name" label="名称"></el-table-column>
						<el-table-column label="值" width="120px">
							<template slot-scope="scope">
								<el-switch v-if="scope.row.type == 'Bool'" :disabled="!(deviceOnline&&scope.row.rw)"
									v-model="scope.row.value" :active-value="true" :inactive-value="false"
									active-color="#13ce66" @change="updateResourceStatus(scope.row)" @click.stop.native></el-switch>
								<el-select style="width:100%" size="small" v-else-if="scope.row.type == 'Enum'"
									v-model="scope.row.value" placeholder="请选择" :disabled="!(deviceOnline&&scope.row.rw)"
									@change="
								    open(
								      scope.row.addr,
								      scope.row.value,
								      scope.row.type,
								      scope.row.items
								    )
								  ">
									<el-option v-for="item in scope.row.items" :key="item.k" :value="item.k"
										:label="item.v"></el-option>
								</el-select>
								<el-button v-else type="primary" plain size="mini"
									:disabled="!(deviceOnline&&scope.row.rw)"
									@click="changeValue($store.state.deviceid, scope.row)">
									{{ scope.row | formatAnalog }}
									{{ scope.row.unit }}
								</el-button>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="200px">
							<template slot-scope="scope">
								<el-button type="warning" size="mini" @click="getResourceConfig(scope.row)" :disabled="!(deviceOnline&&$store.state.deviceRow.permissions.conf)">参数
								</el-button>
								<el-button type="danger" size="mini" @click="getResourceWarn(scope.row)" :disabled="!(deviceOnline&&$store.state.deviceRow.permissions.conf)">报警参数
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<div slot="footer" class="dialog-footer">
				<el-button @click="playDialogVisible = false">关 闭</el-button>
			</div>
		</el-dialog>
		<el-dialog title="提示" :visible.sync="ValueDialogVisible" width="20%" :append-to-body="true">
			<el-input v-model="editValueForm.value" placeholder="请输入内容"></el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="ValueDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="orderValue">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="删除确认" :visible.sync="deleteDialogVisible" width="200px">
			确定删除该摄像头吗
			<div slot="footer" class="dialog-footer">
				<el-button @click="deleteDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleDelete">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="报警设置" :visible.sync="dialogResourceWarnVisible" width="300px">
			<el-form size="small" label-width="80px">
				<div v-if="warnType==='Float32'">
					<el-form-item label="报警上限">
						<el-input placeholder="请输入报警上限" v-model="warnform.up" :disabled="$store.state.deviceRow.permissions.conf===false"></el-input>
					</el-form-item>
					<el-form-item label="报警下限">
						<el-input placeholder="请输入报警下限" v-model="warnform.down" :disabled="$store.state.deviceRow.permissions.conf===false"></el-input>
					</el-form-item>
				</div>
				<el-form-item label="报警模式">
					<el-select placeholder="请选择报警模式" v-model="warnform.mode" class="w-100" :disabled="$store.state.deviceRow.permissions.conf===false">
						<el-option label="无" value="0"></el-option>
						<el-option label="闭合报警" value="1"></el-option>
						<el-option label="断开报警" value="2"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogResourceWarnVisible = false">取 消</el-button>
				<el-button type="primary" @click="updateWarn">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="修改资源配置" :visible.sync="dialogResourceConfigVisible" width="500px">
			<div class="childDeviceConf" v-for="(item, index) in childDeviceConf" :key="index">
				<span>{{ item.name }}</span>
				<el-select v-if="item.type === 'Enum'" v-model="item.value" placeholder="请选择"
					:disabled="$store.state.deviceRow.permissions.conf===false">
					<el-option v-for="dex in item.items" :key="dex.k" :value="dex.k" :label="dex.v"></el-option>
				</el-select>
				<el-input width="50%" v-else v-model="item.value"
					:disabled="$store.state.deviceRow.permissions.conf===false"></el-input>
				<el-button @click="updateResourceConfig(item.addr, item.type, item.value)" size="mini"
					:disabled="$store.state.deviceRow.permissions.conf===false">更改</el-button>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogResourceConfigVisible = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	var player;
	import 'video.js/dist/video-js.css'
	import videojs from "video.js";
	import "videojs-contrib-hls";
	import axios from "axios";
	import other from '../../assets/api/other.js'
	import camera from '../../assets/api/camera.js'
	import device from '../../assets/api/device.js'
	import Breadcrumb from '../components/breadCrumb.vue'
	export default {
		components: {
			Breadcrumb
		},
		data() {
			return {
				videooption:{
				    bigPlayButton: false,
				    textTrackDisplay: false,
				    posterImage: false,
				    errorDisplay: false,
				    controlBar: {
				      volumePanel: {
				        inline: false // 默认是true,横着的
				      },
				      children: [{
				        name: 'playToggle'
				      }, // 播放按钮
				      {
				        name: 'liveDisplay'
				      },
				      {
				        name: 'volumePanel', // 音量控制
				        inline: false // 不使用水平方式
				      },
				      {
				        name: 'FullscreenToggle'
				      } // 全屏
				      ]
				    }
				  },
				player:'',
				showVideo:false,
				configform: [{
						value: ''
					},
					{
						value: ''
					},
					{
						value: ''
					},
					{
						value: ''
					},
					{
						value: ''
					},
					{
						value: ''
					}
				],
				configformFloat: [{
						value: ''
					},
					{
						value: ''
					},
				],
				warnType: 'Bool',
				deviceName:'',
				cameraName:'',
				deviceDetail:{},
				deviceOnline:'',
				deviceid:'',
				videoUrl:'',
				videoid: '',
				deviceList: [],
				childDeviceConf: [],
				tableDataResource:[],
				editValueForm: [],
				deleteDialogVisible: false,
				dialogResourceConfigVisible: false,
				dialogResourceWarnVisible:false,
				playDialogVisible:false,
				ValueDialogVisible: false,
				bread: ['摄像头', ''],
				aliyunOssToken: {},
				provenceArray: [],
				warnform: {
					addr: 0,
					deviceId: 0,
					up: 0,
					down: 0,
					mode: 0
				},
				props: {
					label: "name",
					value: "id"
				},
				tableData: [],
				tableDataChild:[],
				listQuery: {
					index: 1,
					size: 10,
					total:0
				},
				total: 0,
				addVideo: false,
				updateVideo: false,
				addModalVideo: {
					title: "",
					url: "",
					deviceId: "",
					img: ""
				},
				addModelRules: {
					title: [{
						required: true,
						message: "请输入摄像头名称",
						trigger: "blur"
					}],
					url: [{
						required: true,
						message: "请输入链接",
						trigger: "change"
					}],
					img: [{
						message: "请上传封面",
						trigger: "change"
					}]
				},
				updateModalVideo: {
					id: '',
					title: "",
					url: "",
					deviceId: "",
					img: ""
				},
				form: {
					img: ""
				}
			}
		},
		filters:{
			formatAnalog(item) {
				if (item.type != 'Float32') {
					return item.value
				}
			
				var val = item.value
				if (val == 0) {
					val = '0'
				}
				if (val) {
					val = val.toString().replace(/\$|\,/g, '')
					const sign = val == (val = Math.abs(val))
					val = Math.floor(val * 100 + 0.50000000001)
					let cents = val % 100
					val = Math.floor(val / 100).toString()
					if (cents < 10) {
						cents = '0' + cents
					}
					for (var i = 0; i < Math.floor((val.length - (1 + i)) / 3); i++) {
						val =
							val.substring(0, val.length - (4 * i + 3)) +
							',' +
							val.substring(val.length - (4 * i + 3))
					}
					return (sign ? '' : '-') + val + '.' + cents
				}
			}
		},
		computed: {
			hasSelected() {
				return this.multipleSelection.length > 0;
			}
		},
		mounted() {
			this.getVideo()
			this.getdeviceByGroup()
		},
		methods: {
			//获取摄像头列表
			async getVideo() {
				await camera.getVideo(this.listQuery).then(res => {
					if (res.code === 200) {
						this.tableData = res.data
						this.listQuery.total=res.total
					} else {
						this.$message.error('获取摄像头数据失败')
						return
					}
				}, () => {
					this.$message.error('获取摄像头数据失败')
					return
				})
			},
			//获取设备列表
			async getdeviceByGroup(id) {
				this.tableData = []
				await device.getdeviceByGroup().then(res => {
					if (res.code !== 200) {
						this.$message.error('获取设备数据失败')
						return
					} else {
						this.deviceList = res.data
					}
				}, () => {
					this.$message.error('建立连接失败')
				})
			},
			//添加摄像头
			async handelAddVideo(addModelRef) {
				this.$refs[addModelRef].validate(async valid => {
					if (!valid) return;
					this.addModalVideo.img=this.form.img
					await camera.insertVideo(this.addModalVideo).then(res => {
						if (res.code === 200) {
							this.$message.success("添加摄像头成功!");
							this.$refs.uploadInsert.clearFiles();
							this.addVideo = false;
							this.form.img=''
							this.addModalVideo={
								title: "",
								url: "",
								deviceId: "",
								img: ""
							}
							this.getVideo();
						} else {
							this.$message.error('添加失败')
							return
						}
					}, () => {
						this.$message.error('添加失败')
						return
					});
				});
			},
			//获取摄像头行数据
			handleEdit(row) {
				this.updateModalVideo = row;
				this.form.img = row.img;
				this.updateVideo = true;
			},
			//修改摄像头
			async handelUpdateVideo(ModelRef) {
				this.$refs[ModelRef].validate(async valid => {
					if (!valid) return;
					this.updateModalVideo.img=this.form.img
					await camera.updateVideo(this.updateModalVideo).then(res => {
						if (res.code === 200) {
							this.$message.success("修改成功!");
							this.$refs.uploadUpdate.clearFiles();
							this.form.img=''
							this.updateModalVideo={
								id: '',
								title: "",
								url: "",
								deviceId: "",
								img: ""
							}
							this.updateVideo = false;
							this.getVideo();
						} else {
							this.$message.error("修改失败!");
							return
						}
					}, () => {
						this.$message.error("修改失败!");
						return
					});
				});
			},
			//显示删除弹窗
			displayDeleteDialog(info) {
				this.deleteDialogVisible = true
				this.videoid = info.id
			},
			//删除摄像头
			async handleDelete() {
				await camera.deleteVideo(this.videoid).then(res => {
					if (res.code === 200) {
						this.$message.success('删除成功')
						this.deleteDialogVisible = false
						this.getVideo()
					} else {
						this.$message.error('删除失败')
						return
					}
				}, () => {
					this.$message.error('删除失败')
					return
				})
			},
			//获取资源报警设置 通过设备id 资源id
			async getResourceWarn(info) {
				this.resourceid = info.addr
				if (info.type === 'Float32') {
					this.warnType = 'Float32'
				} else {
					this.warnType = 'Bool'
				}
			
				await device.getResourceWarn({
					addr: info.addr,
					deviceId: this.deviceid
				}).then(res => {
					if (res.code === 200) {
						this.dialogResourceWarnVisible = true
						this.warnform.mode = res.data.mode.toString()
						this.warnform.up = res.data.up
						this.warnform.down = res.data.down
					} else {
						this.$message.error('获取报警设置失败')
						return
					}
				}, () => {
					this.$message.error('获取报警设置失败')
					return
				})
			},
			//修改资源报警设置
			async updateWarn() {
				this.warnform.addr = this.resourceid
				this.warnform.deviceId = this.deviceid
				await device.updateWarn(this.warnform).then(res => {
					if (res.code === 200) {
						this.$message.success('修改报警设置成功')
						this.dialogResourceWarnVisible = false
						this.getresourceByDevice(this.$store.state.deviceRow,this.deviceid)
					} else {
						this.$message.error('修改失败')
						return
					}
				}, () => {
					this.$message.error('修改失败')
					return
				})
			},
			//获取资源参数
			async getResourceConfig(item) {
				const body = {
					addr: item.addr,
					deviceId: this.$store.state.deviceid
				}
				device.getResourceConfig(body).then((res) => {
					
					if (res.data) {
						res.data.forEach((item, index) => {
							if (item.type == 'Float32') {
								item.value = Number(item.value).toFixed(2)
							}
						})
						this.childDeviceConf = res.data
						this.dialogResourceConfigVisible = true
					} else {
						this.$message('设置参数为空')
					}
				})
			},
			//修改资源参数
			async updateResourceConfig(addr, type, value) {
				if (this.$store.state.deviceRow.permissions.conf === false) {
					this.$message.error('无权限修改')
					return
				}
				const body = {
					deviceId: this.deviceid,
					regs: [{
						addr: addr,
						type: type,
						value: value
					}]
				}
				await device.updateResourceConfig(body).then((res) => {
					if (res.code == 200) {
						this.$message.success('修改成功')
						this.dialogResourceConfigVisible = false
						this.getresourceByDevice(this.$store.state.deviceRow, this.deviceid)
					} else {
						this.$message.error('修改失败')
					}
				})
			},
			//修改资源状态
			async updateResourceStatus(info) {
				let status = ''
				if (info.value === true) {
					status = true
				} else if (info.value === false) {
					status = false
				}
				let params = {
					"deviceId": this.deviceid,
					"regs": [{
						"addr": info.addr,
						"type": "Bool",
						"value": status,
					}]
				}
				await device.updateResourceConfig(params).then(res => {
					if (res.code === 200) {
						this.$message.success('操作成功')
						this.getresourceByDevice(this.$store.state.deviceRow,this.deviceid)
					} else {
						this.$message.error('操作失败')
						return
					}
				}, () => {
					this.$message.error('操作失败')
					return
				})
			},
			//封面上传
			uploadImg(file) {
				var _self = this;
				let imgType = file.file.type.split("/")[1].toLowerCase();
				if (imgType != "jpg" && imgType != "png" && imgType != "jpeg") {
					this.$message.error("请上传正确的图片类型");
					return;
				}
				other.getOSSToken()
					.then(function(res) {
						_self.aliyunOssToken = res.data;

						let filename = file.file.name; //md5对图片名称进行加密
						let keyValue = `${_self.aliyunOssToken.dir}/` + filename;

						// 组装formdata
						let formdata = new FormData();
						formdata.append("name", file.file.name);
						formdata.append("key", keyValue);
						formdata.append("policy", _self.aliyunOssToken.policy);
						formdata.append("OSSAccessKeyId", _self.aliyunOssToken.accessId);
						formdata.append("success_action_status", 200);
						formdata.append("signature", _self.aliyunOssToken.signature);
						formdata.append("file", file.file);

						_self
							.uploadOSS(formdata, _self.aliyunOssToken.host)
							.then(function(res) {
								_self.form.img = _self.aliyunOssToken.host + "/" + keyValue;
								_self.$message.success("上传成功");
							})
							.catch(function(error) {
								_self.$message.error("上传失败");
							});
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			uploadOSS(formData, url) {
				const config = {
					headers: {
						"Content-Type": "multipart/form-data;boundary=" + new Date().getTime()
					}
				};
				return axios.post(url, formData, config);
			},

			handleOssList(file, filelist) {
				console.log(file)
				this.$message.error("上传失败,超出上传数量最大限制");
			},

			//播放
			handleVideo(row) {
				this.videoUrl=row.url
				this.deviceid=row.deviceId
				this.$store.state.deviceid = row.deviceId
				
				this.cameraName=row.title
				this.deviceName=row.deviceName
				this.playDialogVisible=true
				this.getDeviceDetail(row.deviceId)				
			},

			updateModelFormClose(ref) {
				this.$refs[ref].resetFields();
			},
			handleSizeChange(val) {
				this.listQuery.size = val
				this.getVideo()
			},
			handleCurrentChange(val) {
				this.listQuery.index = val
				this.getVideo()
			},
			playVideo() {
				player = videojs(
				  'my-video',this.videooption,
				  function () {
				    this.play()
				  }
				)
			},
			//弹窗打开后延迟播放视频
			videPlay(){
				let _this=this
				setTimeout(function(){
					_this.playVideo()
				},500)
			},
			closeVideo(){
				player.dispose()
				this.showVideo=false
			},
			async getDeviceDetail(id){
				this.deviceid=id
				await device.getDeviceDetail(id).then(res=>{
					if(res.code===200){
						this.deviceDetail=res.data
						this.deviceOnline=res.data.online
						this.$store.state.deviceRow=res.data
						this.getresourceByDevice(res.data,id)
					}
					else{
						this.$message.error('获取设备详情失败')
						return
					}
				})
			},
			//获通过设备id获取资源
			async getresourceByDevice(row,id) {
				this.tableDataResource = []
				await device.getresourceByDevice({
					deviceId: id
				}).then(res => {
					if (res.code === 200) {
						this.tableDataResource = res.data
						for(let i in this.tableDataResource){
							if(this.tableDataResource[i].value==='true'){
								this.tableDataResource[i].value=true
							}
							else if(this.tableDataResource[i].value==='false'){
								this.tableDataResource[i].value=false
							}
						}
						this.$store.state.tableDataResource = this.tableDataResource
					} else {
						this.$message.error('获取资源数据失败')
						return
					}
				})
			},
			open(addr, value, data, items) {
				this.$confirm('确定修改此数据', '修改数据', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(async () => {
					for (var i in items) {
						if (items[i] === value) {
							value = i
						}
					}
					const body = {
						deviceId: this.deviceid,
						regs: [{
							addr: addr,
							type: data,
							value: value
						}]
					}
					device.getDeviceWriteReg(body)
						.then((res) => {
							this.$message({
								type: 'success',
								message: '修改成功!'
							})
						})
						.catch(() => {
							this.$message({
								type: 'info',
								message: '已取消修改'
							})
						})
				})
			},
			changeValue(id, item) {
				this.id = id
				this.ValueDialogVisible = true
				this.editValueForm = {
					id: id,
					...item
				}
			},
			orderValue() {
				const body = {
					deviceId: this.editValueForm.id,
					regs: [{
						addr: this.editValueForm.addr,
						type: this.editValueForm.type,
						value: this.editValueForm.value
					}]
				}
				device.getDeviceWriteReg(body).then((res) => {
					if ((res.code = 200)) {
						this.ValueDialogVisible = false
						this.getresourceByDevice(this.$store.state.deviceRow, this.deviceid)
					}
				})
			}
		},
		// destroyed() {
		// 	player.dispose()
		// }
	}
</script>

<style>
	.btn-group {
		margin-bottom: 10px !important;
		margin-left: 0px !important;
		margin-right: 10px !important;
	}

	.el-card__body {
		padding-bottom: 10px !important;
	}

	h5,
	h4 {
		margin: 0px !important;
	}
	.vjs-fluid{
		padding-top: 0px !important;
	}
	
	.childDeviceConf {
		margin-bottom: 10px;
		background-color: #efefef;
		box-sizing: border-box;
		padding: 8px;
		border-radius: 5px;
		width: 100%;
		height: 50px;
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
	
	.childDeviceConf>span {
		width: 160px;
	}
	
	.childDeviceConf .el-input {
		width: 170px;
	}
	
	.childDeviceConf .el-select .el-input {
		width: 170px;
	}
</style>
