import {
	get,
	post,
	patch,
	del,
	put,
	putget
} from './request.js'

let api = {
	signup: 'portal/rest/1.0/auth/signUp', // 注册
	getUserData: 'portal/rest/1.0/user', //获取用户信息
	patchName: 'portal/rest/1.0/user',
	putMobile: 'portal/rest/1.0/user/mobile',
	putEmail: 'portal/rest/1.0/user/email',
	putPassword: 'portal/rest/1.0/user/password',
	captcha: 'portal/rest/1.0/auth/captcha', //发送验证码
	FeedbackList: 'portal/rest/1.0/feedback',//获取反馈表格
	Feedback: 'portal/rest/1.0/feedback',//填写反馈
    forget: 'portal/rest/1.0/auth/forget', // 忘记密码
}

// 获取反馈表格
function FeedbackList(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
		}
	}
	return get(api.FeedbackList, params, configs)
}

// 反馈
function Feedback(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
			'content-type':'application/json'
		}
	}
	return post(api.Feedback, params, configs)
}

// 获取用户信
function getUserData(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
		}
	}
	return get(api.getUserData, params, configs)
}


// 修改用户名
function patchName(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
		}
	}
	return patch(api.patchName, params, configs)
}


// 修改邮箱
function putEmail(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
			'content-type':'application/json;charset=UTF-8'
		}
	}
	return putget(api.putEmail, params, configs)
}

// 修改密码
function putPassword(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
		}
	}
	return putget(api.putPassword, params, configs)
}

// 修改手机号
function putMobile(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
		}
	}
	return putget(api.putMobile, params, configs)
}

// 忘记密码
function forget(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
			'content-type':'application/json;charset=UTF-8'
		}
	}
	return put(api.forget, params, configs)
}

// 发送验证码
function captcha(params) {
	var configs = {
		headers: {
		}
	}
	return post(api.captcha, params, configs)
}


// 注册
function signup(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
			'content-type':'application/json'
		}
	}
	return post(api.signup, params, configs)
}

export default {
	getUserData,
	patchName,
	putEmail,
	putPassword,
	putMobile,
	captcha,
	Feedback,
	FeedbackList,
	forget,
	signup
}
