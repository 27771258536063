<template>
  <el-container class="home-container">
    <transition name="el-fade-in-linear">
      <div id="ball" v-drag v-show="flag" class="transition-box">
        <span style="color: #72767b;margin-left: 3%;margin-top: 2%;"
          >报警消息</span
        >
        <i
          class="el-icon-close"
          style="cursor: pointer;margin-right: 3%;margin-top: 2%;float: right;"
          @click="closexiaoxi"
        ></i>

        <el-row>
          <el-col style="padding: 0px 20px;" class="text-right">
            <el-button
              type="text"
              class="text-secondary text-12"
              @click="handleClearWarn"
              >一键清除消息</el-button
            >
          </el-col>
          <el-col class="message-box">
            <div>
              <div class="message-item" v-for="item in messageWarn">
                <label class="text-danger text-13 text-left w-100">{{
                  item.title
                }}</label>
                <label class="text-13 text-left w-100">{{
                  item.message
                }}</label>
                <label class="text-12 text-right text-secondary w-100">{{
                  item.time
                }}</label>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </transition>

    <!-- <div class="box1" style="width: 350px;height: 100%;"  >
  <el-dialog
    title="提示"
    :visible.sync="dialogVisiblet"
    width="100%"
  
    v-dialogDrag
   
   :modal-append-to-body='false'
              :append-to-body="false"
  
   
    :close-on-click-modal="false"
    >
    <span >这是一段信息</span>
  
  </el-dialog> 
</div> -->

    <!-- <audio
      src="../assets/audio/5.wav"
      controls="controls"
      ref="audio"
      style="width: 0px;height: 0px;"
    ></audio>
    <audio
      src="../assets/audio/4.wav"
      controls="controls"
      ref="audio2"
      style="width: 0px;height: 0px;"
    ></audio> -->
    <el-aside :width="asideWidth">
      <div class="avatar-all">
        <img :src="form.icon" alt="头像" />
        <label class="text-13 font-weight-bold">{{ form.username }}</label>
        <el-divider></el-divider>
      </div>
      <el-menu
        :default-active="activeIndex"
        :default-openeds="openeds"
        router
        text-color="#354B5E"
        active-text-color="#409eef"
        :collapse="isCollapse"
        :collapse-transition="false"
      >
        <el-menu-item index="/Home" class="submenu-title">首页</el-menu-item>
        <el-menu-item index="/DeviceList" class="submenu-title"
          >设备列表</el-menu-item
        >
        <el-submenu index="0">
          <template slot="title">
            <span slot="title">监控列表</span>
          </template>
          <el-menu-item index="/Camera">
            摄像头管理
          </el-menu-item>
          <!-- <el-menu-item index="/PlatformCamera">
            平台监控管理
          </el-menu-item> -->
        </el-submenu>
        <el-submenu index="1">
          <template slot="title">
            <span slot="title">设备管理</span>
          </template>
          <el-menu-item index="/DeviceGroup">设备分组</el-menu-item>
          <el-menu-item index="/ResourceSort">资源排序</el-menu-item>
          <el-menu-item index="/DeviceShare">设备分享</el-menu-item>
          <el-menu-item index="/ResourceHide">资源隐藏</el-menu-item>
        </el-submenu>
        <el-menu-item index="/Open" class="submenu-title"
          >开放接口</el-menu-item
        >
        <el-submenu index="2">
          <template slot="title">
            <span slot="title">费用中心</span>
          </template>
          <el-menu-item index="/Service">套餐充值</el-menu-item>
          <el-menu-item index="/ServiceSurplus">套餐余量</el-menu-item>
        </el-submenu>
        <el-menu-item index="/Message" class="submenu-title"
          >消息服务</el-menu-item
        >
        <!-- <el-menu-item index="/route" class="submenu-title">帮助</el-menu-item> -->
      </el-menu>
    </el-aside>
    <el-container>
      <el-header>
        <div>
          <label>点点控管理平台</label>
        </div>
        <div>
          <el-menu
            :default-active="activeIndex2"
            router
            class="el-menu-demo"
            mode="horizontal"
          >
            <el-menu-item
              @click="getWarnHistory"
              v-loading.fullscreen.lock="loading"
              element-loading-text="正在加载报警消息"
              element-loading-spinner="el-icon-loading"
            >
              <i class="el-icon-bell text-danger font-weight-bold"></i>
              <span class="text-danger">({{ messageLen }})</span>
            </el-menu-item>
            <el-menu-item index="/Home">设备中心</el-menu-item>
            <el-menu-item
              ><a
                href="./screen/index.html"
                target="_blank"
                style="text-decoration: none !important;"
                >监控大厅</a
              ></el-menu-item
            >
            <el-menu-item @click="center()">开发者中心</el-menu-item>
            <el-submenu index="4">
              <template slot="title">
                <span slot="title">用户中心</span>
              </template>
              <el-menu-item index="/User">用户信息</el-menu-item>
              <el-menu-item index="/FeekBack">意见反馈</el-menu-item>
              <el-menu-item @click="exitLogin()">退出登录</el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>

    <el-drawer
      :direction="direction"
      title="报警消息"
      :visible.sync="drawer"
      :before-close="handleClose"
      size="350px"
      :wrapperClosable="false"
      :modal="false"
      :modal-append-to-body="false"
    >
      <el-row>
        <el-col style="padding: 0px 20px;" class="text-right">
          <el-button
            type="text"
            class="text-secondary text-12"
            @click="handleClearWarn"
            >一键清除消息</el-button
          >
        </el-col>
        <el-col class="message-box">
          <div>
            <div class="message-item" v-for="item in messageWarn">
              <label class="text-danger text-13 text-left w-100">{{
                item.title
              }}</label>
              <label class="text-13 text-left w-100">{{ item.message }}</label>
              <label class="text-12 text-right text-secondary w-100">{{
                item.time
              }}</label>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </el-container>
</template>

<script>
import jwtDecode from "jwt-decode";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
import device from "../assets/api/device.js";
import login from "../assets/api/login.js";
import user from "../assets/api/user.js";
import mqtt from "mqtt";
import { sha256 } from "js-sha256";
export default {
  directives: {
    drag: {
      //  el当前元素 binding加参数 通过vnode获取vue实例
      inserted: (el, binding, vnode) => {
        // 绑定鼠标按下事件
        document.addEventListener("mousedown", (e) => {
          // 阻止事件冒泡
          e.stopPropagation();
          // 只绑定当前元素 当拖拽子元素时 不会发生移动
          if (e.target === el) {
            // 设置变量postionFlag 绑定在 父元素上 判断当前鼠标是否在要拖拽的元素内
            el.postionFlag =
              e.clientX > el.offsetLeft &&
              e.clientX < el.offsetLeft + el.clientWidth &&
              e.clientY > el.offsetTop &&
              e.clientY < el.offsetTop + el.clientHeight;
            if (el.postionFlag) {
              // 由于定位居中使用的是  left: 0;top: 0;right: 0;bottom: 0;margin: auto; 此时元素 具体top和left需要重新赋值 方便后面计算
              el.style.top = el.offsetTop + "px";
              el.style.left = el.offsetLeft + "px";
              // 重写margin属性 不然 有问题
              el.style.margin = "0px";
              // 将鼠标当前坐标挂在到window对象上
              window.OriginMouseX = e.clientX;
              window.OriginMouseY = e.clientY;
            }
          }
        });
        // 鼠标移动事件
        document.addEventListener("mousemove", function(e) {
          // 判断是否出界函数
          function ifOut(left, top, relativeElement) {
            return (
              left <= relativeElement.clientWidth - el.clientWidth &&
              top <= relativeElement.clientHeight - el.clientHeight &&
              left >= 0 &&
              top >= 0
            );
          }
          // 只有鼠标在 元素内 鼠标移动才是有意义的
          if (el.postionFlag) {
            // 阻止默认事件 加上 不然有问题
            e.preventDefault();
            // 根据 ref找到需要相对的元素，没有就默认是body
            const relativeElement = vnode.context.$refs[binding.value]
              ? vnode.context.$refs[binding.value]
              : document.body;
            // 确定元素新坐标
            const newLeft = e.clientX - window.OriginMouseX + el.offsetLeft;
            const newTop = e.clientY - window.OriginMouseY + el.offsetTop;
            if (ifOut(newLeft, newTop, relativeElement)) {
              // 重新挂载鼠标坐标
              window.OriginMouseX = e.clientX;
              window.OriginMouseY = e.clientY;
              // 改变元素位置
              el.style.left = newLeft + "px";
              el.style.top = newTop + "px";
            }
          } else {
            el.postionFlag = false;
          }
        });
        document.addEventListener("mouseup", function(e) {
          // 鼠标抬起 停止移动
          el.postionFlag = false;
        });
      },
    },
  },

  data() {
    return {
      flag: false,
      dialogVisiblet: true,
      direction: "ltr",
      tableDataResource: [],
      loading: false,
      activeIndex: "1",
      activeIndex2: "2",
      openeds: ["1"],
      isCollapse: false,
      asideWidth: "160px",
      collapseFlag: true,
      name: "",
      drawer: false,
      options: {
        protocolId: "MQTT", // Or 'MQIsdp' in MQTT 3.1 and 5.0
        protocolVersion: 5, // Or 3 in MQTT 3.1, or 5 in MQTT 5.0
        properties: {
          receiveMaximum: 1000,
        },
        clean: true, // 保留回话
        connectTimeout: 4000, // 超时时间
        // 认证信息
        clientId: "",
        username: "",
        password: "",
      },
      // connectUrl: "wss://mqcpv2.sintien.com/mqtt",
      connectUrl: "wss://mqtt-wss.devcld.com:443/mqtt",
      // connectUrl: "ws://mqtt.ddk.sintien.com:8083/mqtt",
      // connectUrl: "ws://mqtt.devcld.com:1883/mqtt",
      client: "",
      deviceGroupData: [],
      conf: "",
      dev: "",
      run: "",
      warn: "",
      messageWarn: [],
      form: {
        icon: require("../assets/image/photo.png"),
        username: "",
        mobile: "",
        email: "",
      },
      messageLen: 0,
      url: "https://developer.devcld.com/#/login",
      url2: "https://ddk.devcld.com/screen",
    };
  },
  created() {
    // this.getResize();

    this.handletoken();
    this.client = mqtt.connect(this.connectUrl, this.options);
    this.init();

    device.getdeviceGroup().then((res) => {
      if (res === undefined) {
        this.$router.push("/Login");
      } else {
        if (res.code === 200) {
          if (res.data) {
            const Data = res.data.map((item) => {
              return {
                ...item,
                devicesList: [],
              };
            });
            this.deviceGroupData = Data;
          }
        }
      }
    });
  },
  beforeDestroy() {
    this.client.end();
  },
  mounted() {
    // this.getInfo()
    this.name = window.localStorage.getItem("name");
    // this.getWarnHistory()
    this.getUserData();
  },
  methods: {
    closexiaoxi() {
      this.flag = false;
    },
    add() {
      this.drawer = true;
      this.flag = true;
    },

    center() {
      let token = localStorage.getItem("token").split(".")[1];
      let decode = jwtDecode(localStorage.getItem("token"));
      window.open(this.url + `?userid=${decode.sub}`, "_blank");
    },
    screen() {
      let decode = jwtDecode(localStorage.getItem("token"));
      window.open(this.url2 + `?userid=${decode.sub}`, "_blank");
    },
    //获取用户信息
    async getUserData() {
      await user.getUserData().then(
        (res) => {
          if (res === undefined) {
            this.$router.push("/Login");
          } else {
            if (res.code === 200) {
              this.form = res.data;
            } else {
              this.$message.error("获取用户信息失败");
              return;
            }
          }
        },
        () => {
          this.$message.error("获取用户信息失败");
          return;
        }
      );
    },
    //获取历史报警消息
    async getWarnHistory() {
      this.messageWarn = [];
      this.loading = true;
      await login
        .pushHistory({
          messageType: "WARN",
        })
        .then(
          (res) => {
            if (res.code === 200) {
              if (res.data) {
                this.flag = true;
                //	this.drawer = true
                this.messageLen = res.data.length;
                const newData = res.data.map((item) => {
                  const time = this.utcMoment(item.time);
                  return {
                    ...item,
                    time,
                  };
                });
                this.messageWarn.unshift(...newData);
                this.loading = false;
              }
            } else {
              this.$message.error("获取报警历史失败");
              this.loading = false;
              return;
            }
          },
          () => {
            this.$message.error("获取报警历史失败");
            this.loading = false;
            return;
          }
        );
    },
    exitLogin() {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("refreshToken");
      this.$store.state.deviceRow.permissions.conf = false;

      let state = this.$store.state;
      state.tableDataDevice = [];
      state.newListDevice = [];
      state.tableDataResource = [];
      state.deviceid = "";
      state.groupid = "";

      this.$router.push({
        path: "/Login",
      });
    },
    //获取用户信息
    async getInfo() {
      await login.getInfo().then(
        (res) => {
          if (res.code == 200) {
            window.localStorage.setItem("name", res.data.tenantName);
          } else {
            this.$message.error("获取用户信息失败");
          }
        },
        () => {
          this.$message.error("获取用户信息失败");
        }
      );
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // mqtt账号密码
    handletoken() {
      const token = localStorage.getItem("token").split(".")[1];
      const user = JSON.parse(window.atob(token)).sub;
      this.options.clientId = `user_${user}_${login.guid()}`;
      this.options.username = `user_${user}`;
      this.options.password = sha256(`${user}`).toUpperCase();
    },
    //mqtt初始化
    init() {
      // mqtt连接
      this.client.on("connect", (e) => {
        console.log("连接成功:");
        this.client.subscribe(
          [
            `message/status/${this.options.username}`,
            `message/reg/${this.options.username}`,
            `message/warn/${this.options.username}`,
            `message/bind/${this.options.username}`,
            `message/permission/${this.options.username}`,
          ],
          {
            qos: 1,
          },
          (error) => {
            if (!error) {
              console.log("订阅成功");
            } else {
              console.log("订阅失败");
            }
          }
        );
      });
      // 接收消息处理
      this.client.on("message", (topic, message) => {
        // 上下线消息
        if (topic == `message/status/${this.options.username}`) {
          const statusMqttData = JSON.parse(message.toString());
          this.deviceGroupData.forEach((item) => {
            if (item.devicesList.length != 0) {
              item.devicesList.forEach((devitem) => {
                if (devitem.id == statusMqttData.deviceId) {
                  devitem.online = statusMqttData.online;
                }
              });
            }
          });
          //设备绑定/解绑推送，直接刷新当前查看的设备表格
          this.getdeviceByGroup();
        }

        // reg 消息
        if (topic == `message/reg/${this.options.username}`) {
          const mqttData = JSON.parse(message.toString());

          //读取本地存储的资源数
          let resdata = this.$store.state.tableDataResource;
          //判断推送消息的deviceId是否与当前正在显示的数据的deviceid相同，相同则刷新数据，不相同不操作
          // resdata为当前网页上显示的资源数据，直接循环遍历资源列表修改值
          if (this.$store.state.deviceid !== "") {
            if (mqttData.deviceId === this.$store.state.deviceid) {
              resdata.forEach((regitem) => {
                mqttData.regItems.forEach((mqttRegitem) => {
                  if (mqttRegitem.type == "Bool") {
                    if (mqttRegitem.regData === "false") {
                      mqttRegitem.regData = false;
                    } else if (mqttRegitem.regData === "true") {
                      mqttRegitem.regData = true;
                    }
                  }
                  if (regitem.addr == mqttRegitem.regAddr) {
                    regitem.value = mqttRegitem.regData;
                  }
                });
              });
              //遍历完新数据存入本地
              this.$store.state.tableDataResource = resdata;
            }
          }
          // const time = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          // this.warnNotify(`设备[${mqttData.deviceId}][${mqttData.deviceName}]资源[${mqttData.regItems[0].regAddr}][${mqttData.regItems[0].regName}]发出报警`,time)
          // this.$refs.audio.play()
        }

        // 报警消息
        if (topic == `message/warn/${this.options.username}`) {
          const time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          const messageDate = JSON.parse(message.toString());
          messageDate.datetime = time;

          let msg = {
            userId: 0,
            messageType: "WARN",
            title: "设备报警提醒",
            message: `您的设备[${messageDate.deviceId}] [${
              messageDate.deviceName
            }] [${messageDate.regItems[0].regAddr}][${
              messageDate.regItems[0].regName
            }] ${
              messageDate.regItems[0].regData == "true"
                ? "触发报警"
                : messageDate.regItems[0].regData == "false"
                ? "解除报警"
                : ""
            },值[${messageDate.regItems[0].regData}]`,
            time: time,
          };
          //	this.drawer = true
          this.flag = true;
          this.messageWarn.unshift(msg);
          this.messageLen = this.messageWarn.length;

          if (messageDate.regItems[0].regData == "true") {
            this.audioPlay1();
          } else if (messageDate.regItems[0].regData == "false") {
            this.audioPlay2();
          }
        }

        // 设备绑定解绑
        if (topic == `message/bind/${this.options.username}`) {
          const bindMqttData = JSON.parse(message.toString());
          this.deviceGroupData.forEach((item, dex) => {
            if (item.devicesList.length != 0) {
              // 循环遍历设备列表
              item.devicesList.forEach((devitem, index) => {
                if (devitem.id == bindMqttData.deviceId) {
                  if (bindMqttData.bind == false) {
                    this.deviceGroupData[dex].devicesList.splice(index, 1);
                  }
                }
              });
            }
          });
          //设备绑定/解绑推送，直接刷新当前查看的设备表格
          this.getdeviceByGroup();
        }

        // 设备权限
        if (topic == `message/permission/${this.options.username}`) {
          const statusMqttData = JSON.parse(message.toString());
          this.conf = statusMqttData.conf;

          //设备权限更改后，刷新当前设备数据，资源数据
          this.getdeviceByGroup();
          this.getresourceByDevice();
        }
      });
      // 断开发起重连
      this.client.on("reconnect", (error) => {
        console.log("正在重连:", error);
      });
      // 链接异常处理
      this.client.on("error", (error) => {
        console.log("连接失败:", error);
      });
    },
    utcMoment(d) {
      if (!d) {
        return "--";
      }
      var i = -(new Date().getTimezoneOffset() / 60);
      var len = new Date(d).getTime();
      var utcTime = len;
      return moment(new Date(utcTime + 3600000 * i)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    //清空报警弹窗
    handleClearWarn() {
      this.messageWarn = [];
      this.$message.success("清除成功");
    },
    warnNotify(message, time) {
      if (message !== "" && time === "") {
        this.$notify({
          title: "报警",
          message: message,
          type: "warning",
          duration: 10000,
        });
      }
      if (message === "" && time === "") {
        this.$notify({
          title: "报警",
          message: `报警提示`,
          type: "warning",
          duration: 10000,
        });
      } else {
        this.$notify({
          title: "报警",
          message: `<div class="message-box">
									<div>
										<div class="message-item">
											<label class="text-13 text-left w-100">${message}</label><br/><label class="text-12 text-right text-secondary w-100">${time}</label>
										</div>
									</div>
								</div>`,
          dangerouslyUseHTMLString: true,
          type: "warning",
          duration: 10000,
        });
      }
    },
    getdeviceByGroup() {
      if (this.$store.state.groupid !== "") {
        device
          .getdeviceByGroup({
            groupId: this.$store.state.groupid,
          })
          .then(
            (res) => {
              if (res.code === 200) {
                this.$store.state.tableDataDevice = res.data;

                //为排序操作做准备
                if (res.data) {
                  let data = this.$store.state.tableDataDevice.map((item) => {
                    return item.id;
                  });
                  this.$store.state.newListDevice = data;
                }
              } else {
                this.$message.error("更新设备数据失败");
                return;
              }
            },
            () => {
              this.$message.error("更新设备数据失败");
              return;
            }
          );
      }
    },
    getresourceByDevice() {
      if (this.$store.state.deviceid !== "") {
        //用设备id获取匹配数据赋值到deviceRow
        let deviceData = this.$store.state.tableDataDevice;
        for (let i in deviceData) {
          if (deviceData.id === this.$store.state.deviceid) {
            this.$store.state.deviceRow = deviceData[i];
          }
        }

        device
          .getresourceByDevice({
            deviceId: this.$store.state.deviceid,
          })
          .then(
            (res) => {
              if (res.code === 200) {
                this.tableDataResource = res.data;

                for (let i in this.tableDataResource) {
                  if (this.tableDataResource[i].value === "true") {
                    this.tableDataResource[i].value = true;
                  } else if (this.tableDataResource[i].value === "false") {
                    this.tableDataResource[i].value = false;
                  }
                }
                this.$store.state.tableDataResource = this.tableDataResource;
              } else {
                this.$message.error("更新资源数据失败");
                return;
              }
            },
            () => {
              this.$message.error("更新资源数据失败");
              return;
            }
          );
      }
    },
    audioPlay1() {
      if (document.getElementById("audio1")) {
        document.getElementById("audio1").remove();
      }
      let audio1 = document.createElement("audio");
      audio1.setAttribute("src", require("../assets/audio/4.wav"));
      audio1.setAttribute("id", "audio1");
      audio1.setAttribute("style", "width: 0px;height: 0px;");
      document.getElementsByTagName("body")[0].appendChild(audio1);
      audio1.play();

      let audio2 = document.getElementById("audio2");
      if (audio2) {
        audio2.remove();
      }
    },
    audioPlay2() {
      if (document.getElementById("audio2")) {
        document.getElementById("audio2").remove();
      }
      let audio2 = document.createElement("audio");
      audio2.setAttribute("src", require("../assets/audio/5.wav"));
      audio2.setAttribute("id", "audio2");
      audio2.setAttribute("style", "width: 0px;height: 0px;");
      document.getElementsByTagName("body")[0].appendChild(audio2);
      audio2.play();

      let audio1 = document.getElementById("audio1");
      if (audio1) {
        audio1.remove();
      }
    },
  },
};
</script>

<style scoped lang="less">
.box1 {
  position: absolute !important;
}
/deep/.el-dialog__wrapper {
  position: absolute !important;
}
/deep/.v-modal {
  position: absolute !important;
}
/deep/.el-drawer__wrapper {
  //display: none;
  border-left: 1px solid #e5e5e5;
  box-shadow: 0 8px 10px 0px rgba(0, 0, 0, 0.1) !important;
  width: 350px !important;
  position: fixed !important;
  right: 0px !important;
  left: unset !important;
}

// 消息盒子
.message-box {
  padding: 0px 10px;

  div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .message-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      background-color: #ffffff;
      border: 2px solid #e5e5e5;
      border-radius: 6px;
      padding: 10px;
      margin-bottom: 10px;
    }
  }
}

// 头像
.avatar-all {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  img {
    width: 38px;
    height: 38px;
    border-radius: 500px;
    border: 1px solid #e1e1e1;
  }

  label {
    margin-top: 10px;
  }
}

//分割线
.el-divider--horizontal {
  margin: 10px 0px !important;
}

//抽屉
.el-drawer {
  background-color: #f1f1f1 !important;

  // /deep/ #el-drawer__title {
  // 	margin-bottom: 10px !important;
  // }

  // /deep/ .el-drawer__header {
  // 	margin-bottom: 10px !important;
  // }
}

//顶部
.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff !important;
  border-bottom: 1px solid #e9e9e9;

  label {
    font-size: 14px;
    font-weight: bold;
  }

  .el-menu {
    background-color: #fff !important;
    text-align: center;

    .el-submenu .el-menu-item {
      min-width: 100px !important;
    }
  }

  .el-menu-item {
    color: #333 !important;
    font-weight: bold !important;
  }

  //下拉框
  /deep/ .el-submenu__title {
    span {
      color: #333 !important;
      font-weight: bold;
    }
  }
}

//主区域
.el-main {
  background-color: #eef0f1 !important;
}

//侧栏
.el-aside {
  background-color: #fff !important;
  border-right: 1px solid #e5e5e5;

  .el-menu {
    color: #fff !important;
    border: none;
    background-color: #fff !important;
    text-align: center;

    .el-submenu .el-menu-item {
      height: 50px;
      line-height: 50px;
      min-width: 100px !important;
    }

    .el-menu-item {
      color: #333 !important;
    }

    .el-submenu__title {
      span {
        color: #333 !important;
        font-size: 15px !important;
        font-weight: bold;
      }
    }
  }
}

.submenu-title {
  font-size: 15px !important;
  font-weight: bold;
}

.el-container {
  height: 100%;
}

.home-container {
  height: 100% !important;
}
#containers {
  position: relative;
  width: 800px;
  height: 400px;
  background-color: black;
}
#login {
  width: 400px;
  height: 300px;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
#ball {
  width: 30%;
  height: 30%;
  background-color: #fff;

  position: absolute;
  left: 0;
  top: -25%;
  right: -65%;
  bottom: 0;
  margin: auto;
  z-index: 9999;
  overflow: scroll;
}
</style>
