<template>
	<div>
		<Breadcrumb :first='bread[0]' :second="bread[1]"></Breadcrumb>
		<el-row>
			<el-col>
				<el-card class="shadow-sm">
					<el-form>
						<el-form-item class="mb-0" label="套餐类型" label-width="100px">
							<div>
								<el-button plain type="primary" size="mini" :class="active===0?'m-l activeClass':'m-l'"
									@click="getserviceItem(0)">短信套餐
								</el-button>
								<el-button plain type="primary" size="mini" :class="active===1?'m-l activeClass':'m-l'"
									@click="getserviceItem(1)">语音套餐
								</el-button>
								<el-button plain type="primary" size="mini" :class="active===2?'m-l activeClass':'m-l'"
									@click="getserviceItemIotCard">
									物联网卡</el-button>
							</div>
						</el-form-item>
					</el-form>
				</el-card>
			</el-col>
			<el-col>
				<el-card class="shadow-sm">
					<el-form>
						<el-form-item class="mb-0" label="规格" label-width="100px">
							<div>
								<el-button v-if="type===0||type===1" plain type="primary" size="mini"
									:class="activeChild===index?'m-l activeClass':'m-l'"
									v-for="(item,index) in serviceList" @click="countResult(item.type,index,item.id)">
									{{item.num}}条
								</el-button>
								<el-button v-if="type===2" plain type="primary" size="mini"
									:class="activeChild===index?'m-l activeClass':'m-l'"
									v-for="(item,index) in iotcardList" @click="countResult(2,index,item.id)">
									{{item.month}}月
								</el-button>
							</div>
						</el-form-item>
					</el-form>
				</el-card>
			</el-col>
			<el-col v-if="resultShow">
				<el-card class="shadow-sm">
					<div style="display: flex;align-items: center;justify-content: flex-end;">
						<div style="display: flex;flex-direction: column;align-items: center;padding-right: 30px;">
							<div style="display: flex;align-items: baseline;">费用：<span
									class="text-danger text-24 font-weight-bold">{{countList.discount}} 元</span></div>
							<div>原价：<span class="text-secondary"><s>{{countList.discount}} 元</s></span></div>
						</div>
						<el-button type="danger" style="height: 50px;width:200px;" @click="handlePaymentModal">确 认 支 付
						</el-button>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<el-dialog title="选择支付方式" :visible.sync="dialogVisible" width="800px" :append-to-body="true">
			<el-row>
				<el-col v-if="type===2">
					<el-table :data="tableDataIdCar" size="mini" stripe border
						:header-cell-style="{ 'background': '#F3F3F3','text-align':'center'}"
						:cell-style="{'text-align':'center'}">
						<el-table-column prop="deviceName" label="设备名称"></el-table-column>
						<el-table-column label="状态">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.status===true" size="mini" type="success">
									正常
								</el-tag>
								<el-tag v-if="scope.row.status===false" size="mini" type="info">
									欠费
								</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="activeTime" label="激活时间"></el-table-column>
						<el-table-column prop="expireTime" label="到期时间"></el-table-column>
						<el-table-column label="选择">
							<template slot-scope="scope">
								<el-button size="mini" type="primary" plain @click="getIotCardInfo(scope.row)">
									选择
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
				<el-col class="content_margin_top" v-if="type===2">
					<span class="text-secondary">选择的设备：</span>{{labeldeviceName}}
				</el-col>
				<el-col class="content_margin_top">
					<el-radio-group v-model="paymenttype">
						<div
							style="display: flex;align-items: center;justify-content: space-evenly;width: 100% !important;">
							<el-radio label="ALIPAY_QRCODE" border style="width: 300px;">支付宝支付</el-radio>
							<el-radio label="WECHATPAY_NATIVE" border style="width: 300px;">微信支付</el-radio>
						</div>

					</el-radio-group>
				</el-col>
			</el-row>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" :loading="paymentLoading" @click="handlePayment">
					{{ paymentLoading ? "加载中" : "确 定" }}
				</el-button>
			</span>
		</el-dialog>

		<el-dialog title="扫码支付" :visible.sync="paymentVisible" width="400px" :append-to-body="true">
			<div class="text-center"
				style="height: 343px;width: 100%;display: flex;justify-content: center;align-items: center;flex-direction: column;">
				<div id="qrcode" ref="qrcode" style="text-align: center"></div>
				<label class="w-100 text-center" style="margin-top: 10px;font-size: 20px;font-weight: bold;">
					<span v-if="paymenttype==='ALIPAY_QRCODE'">支付宝</span>
					<span v-else-if="paymenttype==='WECHATPAY_NATIVE'">微信支付</span>
				</label>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import QRCode from "qrcodejs2";
	import service from '../../assets/api/service.js'
	import Breadcrumb from '../components/breadCrumb.vue'
	export default {
		components: {
			Breadcrumb
		},
		data() {
			return {
				paymenttype: '',
				dialogVisible: false,
				//控制选中效果
				active: 0,
				activeChild: '',
				bread: ['费用中心', '套餐充值'],
				//短信、语音数据
				serviceList: [],
				//物联网卡数据
				iotcardList: [],
				//结算显示数据
				countList: {},
				//短信0，语音1,筛选类型
				type: 0,
				//控制结算行显示
				resultShow: false,
				form: {},
				paymentLoading: false,
				paymentVisible: false,
				deviceModal: false,
				statusTimeId: "",
				cleartimeout: "",
				paymentData: {}, //支付回调参数
				iccid: "",
				tableDataIdCar: [],
				labeldeviceName: '未选择设备',
			}
		},
		mounted() {
			this.getserviceItem(0)
		},
		methods: {
			//获取物联网卡情况id
			getIotCardInfo(info) {
				this.iccid = info.iccid
				this.labeldeviceName = info.deviceName
			},
			//物联网卡套餐情况表
			async getIotCardData() {
				await service.getIotCardData().then(res => {
					if (res.code === 200) {
						this.tableDataIdCar = res.data
					} else {
						this.$message.error('获取套餐剩余量失败')
						return
					}
				}, () => {
					this.$message.error('获取套餐剩余量失败')
					return
				})
			},
			//根据套餐类型获取套餐规格
			async getserviceItem(type) {
				this.type = type
				this.active = type
				this.activeChild = ''
				this.resultShow = false
				this.countList = {}
				await service.getserviceItem({
					type: type
				}).then(res => {
					if (res.code === 200) {
						this.serviceList = res.data
					} else {
						this.$message.errpr('获取套餐规格失败')
						return
					}
				}, () => {
					this.$message.errpr('获取套餐规格失败')
					return
				})
			},
			//获取物联网卡套餐规格
			async getserviceItemIotCard() {
				this.type = 2
				this.active = 2
				this.activeChild = ''
				this.resultShow = false
				this.countList = {}
				await service.getserviceItemIotCard().then(res => {
					if (res.code === 200) {
						this.iotcardList = res.data
					} else {
						this.$message.error('获取套餐规格失败')
						return
					}
				}, () => {
					this.$message.error('获取套餐规格失败')
					return
				})
			},
			//显示结果(原价，优惠…)
			countResult(type, index, id) {
				this.resultShow = true
				this.activeChild = index
				let sindex = ''

				//根据类型去相关数组中查找对应数据
				if (type === 0 || type === 1) {
					sindex = this.serviceList.findIndex(item => {
						return item.id === id
					})
					this.countList = this.serviceList[sindex]
				} else if (type === 2) {
					sindex = this.iotcardList.findIndex(item => {
						return item.id === id
					})
					this.countList = this.iotcardList[sindex]
				}
			},
			// 支付按钮
			handlePaymentModal() {
				if (this.type == 2) {
					this.getIotCardData()
					this.labeldeviceName=''
				}
				this.dialogVisible = true;
			},
			//选择方式，确认支付
			handlePayment() {
				if (this.paymenttype === '') {
					this.$message.error('请选择付款方式')
					return
				}
				this.paymentLoading = true;
				if (this.type === 2) {
					if (this.iccid === '') {
						this.$message.warn("请先选择上方设备");
						return
					} else {
						service.iot({
							iccid: this.iccid,
							itemId: this.countList.id,
							paymentType: this.paymenttype
						}).then((res) => {
							if (res.code === 200) {

								this.paymentData = res.data;
								this.paymentVisible = true;
								this.paymentLoading = false;
								this.$nextTick(() => {
									this.$refs.qrcode.innerHTML = "";
									this.qrcode();
								});
								this.statusTimeId = setTimeout(this.getPayStatus, 1000);
								this.cleartimeout = setTimeout(this.handleTotalTime, 300000);
							} else {
								this.$message.error('提交订单失败')
								return
							}
						});
					}
				} else {
					service.paymentWarn({
							itemId: this.countList.id,
							paymentType: this.paymenttype,
						})
						.then((res) => {
							if (res.code === 200) {

								this.paymentData = res.data;
								this.paymentVisible = true;
								this.paymentLoading = false;
								this.$nextTick(() => {
									this.$refs.qrcode.innerHTML = "";
									this.qrcode();
								});
								this.statusTimeId = setTimeout(this.getPayStatus, 1000);
								this.cleartimeout = setTimeout(this.handleTotalTime, 300000);

							} else {
								this.$message.error('提交订单失败')
								return
							}
						})
						.catch((err) => {
							this.paymentLoading = false;
						});
				}
			},
			//生成付款码
			qrcode() {
				var qrcode = new QRCode(this.$refs.qrcode, {
					correctLevel: 2,
					text: this.paymentData.response, //二维码内容
					width: 200,
					height: 200,
					colorDark: "#000000",
					colorLight: "#ffffff",
				});
			},
			//获取支付状态
			getPayStatus() {
				let data = {
					orderId: this.paymentData.orderId
				};
				service.paymentStatus(data)
					.then((res) => {
						this.paymentstatus = res.data;
						if (res.data) {
							this.$message.success("支付成功!");
							this.paymentVisible = false;
							this.dialogVisible = false;
							clearTimeout(this.statusTimeId);
							this.paymentData = {}
							if(this.type===2){
								this.iccid=''
							}
						} else {
							this.statusTimeId = setTimeout(this.getPayStatus, 1000);
						}
					})
					.catch((err) => {
						// 接口报错，继续轮询
						this.statusTimeId = setTimeout(this.getPayStatus, 1000);
					});
			},

			// 轮询超过5分钟 清空掉轮询
			handleTotalTime() {
				this.paymentstatus ? "" : clearTimeout(this.statusTimeId);
				clearTimeout(this.cleartimeout);
			},
		},
	}
</script>

<style scoped>
	.el-card__body {
		padding: 20px !important;
	}

	.m-l {
		width: 130px !important;
		margin-left: 30px !important;
	}

	.activeClass {
		background-color: #409EFF !important;
		color: #fff !important;
	}

	.el-radio-group {
		width: 100%;
	}
</style>
