import {
	request,
	get,
	post,
	patch,
	del
} from './request.js'

let api = {
	getopenapi: 'portal/rest/1.0/openapi', //获取开放接口列表
	insertNewApiRemark:'portal/rest/1.0/openapi',//添加开放接口新备注
	deleteApiRemark:'portal/rest/1.0/openapi',//添加开放接口新备注
	getOssKey: 'portal/rest/1.0/oss/sign', // 上传
	
}

// 获取开放接口列表
function getopenapi(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token')
		}
	}
	return get(api.getopenapi, params, configs)
}

// 添加开放接口新备注
function insertNewApiRemark(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
			'content-type':'application/json'
		}
	}
	return post(api.insertNewApiRemark, params, configs)
}

//  删除开放接口新备注
function deleteApiRemark(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
		}
	}
	return del(api.deleteApiRemark, params, configs)
}

// 获取开放接口列表
function getOSSToken(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token')
		}
	}
	return get(api.getOssKey, params, configs)
}


export default {
	getopenapi,
	insertNewApiRemark,
	deleteApiRemark,
	getOSSToken
}
