<template>
	<div>
		<Breadcrumb :first='bread[0]' :second="bread[1]"></Breadcrumb>
		<el-row :gutter="12">
			<el-col :span="12">
				<el-card class="shadow-sm">
					<el-row>
						<el-col>
							<h5>套餐余量(短信、语音)</h5>
						</el-col>
					</el-row>
					<el-row class="content_margin_top" style="padding-bottom: 30px;">
						<el-col :span="12" class="text-center">
							<el-row>
								<el-col><label class="small_title">短信</label></el-col>
								<el-col><label class="small_content">{{ tableData.sms }}</label>
								</el-col>
							</el-row>
						</el-col>
						<el-col :span="12" class="text-center">
							<el-row>
								<el-col><label class="small_title">语音</label></el-col>
								<el-col><label class="small_content">{{ tableData.tel}}</label>
								</el-col>
							</el-row>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="shadow-sm">
					<el-row>
						<el-col>
							<h5>操作</h5>
						</el-col>
					</el-row>
					<el-row class="content_margin_top">
						<el-col style="padding-bottom: 17px;">
							<div style="display: flex;justify-content: space-evenly;align-items: center;">
								<el-button style="width: 25%;height: 80px;" @click="getHistory1">
									充值记录
								</el-button>
								<el-button style="width: 25%;height: 80px;" @click="getHistory2">
									消费记录
								</el-button>
								<el-button style="width: 25%;height: 80px;" type="danger" @click="toServive">
									去充值
								</el-button>
							</div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
			<el-col>
				<el-card class="shadow-sm">
					<el-row>
						<el-col>
							<h5>套餐剩余(物联网卡)</h5>
						</el-col>
						<el-col class="content_margin_top">
							<el-table :data="tableData.iotCardBalanceList" size="mini" stripe border
								style="width: 100%;"
								:header-cell-style="{ 'background': '#F3F3F3','text-align':'center'}"
								:cell-style="{'text-align':'center'}">
								<el-table-column prop="deviceId" label="设备id" width="140px">
								</el-table-column>
								<el-table-column prop="deviceName" label="设备名称"></el-table-column>
								<el-table-column label="消费状态">
									<template slot-scope="scope">
										<el-tag v-if="scope.row.status===true" size="mini" type="success">
											正常
										</el-tag>
									</template>
								</el-table-column>
								<el-table-column prop="expireTime" label="到期时间"></el-table-column>
							</el-table>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
		</el-row>
		<el-dialog title="充值记录" :visible.sync="dialogHistoryVisible1" width="1000px">
			<el-row>
				<el-col>
					<el-date-picker v-model="page1.date" type="date" placeholder="选择日期" size="small"
						@change="getHistory1()" value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-col>
				<el-col class="content_margin_top">
					<el-table :data="tableDataHistory1" size="mini" stripe border
						:header-cell-style="{'text-align':'center','background':'#FAFAFA'}"
						:cell-style="{'text-align':'center'}">
						<el-table-column prop="id" label="订单号">
						</el-table-column>
						<el-table-column prop="paymentOrderNo" label="支付单号">
						</el-table-column>
						<el-table-column label="充值类型" width="100px">
							<template slot-scope="scope">
								<el-tag type="warning" size="mini" v-if="scope.row.extendsType==='WARN'">报警</el-tag>
								<el-tag type="primary" size="mini" v-if="scope.row.extendsType==='IOT_CARD'">物联网卡
								</el-tag>
							</template>
						</el-table-column>
						<el-table-column label="支付类型" width="100px">
							<template slot-scope="scope">
								<span v-if="scope.row.paymentType==='ALIPAY_APP'||scope.row.paymentType==='ALIPAY_QRCODE'">支付宝</span>
								<span v-if="scope.row.paymentType==='WECHATPAY_APP'">微信</span>
							</template>
						</el-table-column>
						<el-table-column prop="price" label="支付金额" sortable width="150px">
						</el-table-column>
						<el-table-column label="启动时间" width="150px">
							<template slot-scope="scope">
								<span>
									{{scope.row.createDate|TimeParse}}
								</span>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
						:current-page="page1.index" :page-sizes="[30, 50, 70, 90]" :page-size="page1.size"
						layout="total, sizes, prev, pager, next, jumper" :total="page1.total">
					</el-pagination>
				</el-col>
			</el-row>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogHistoryVisible1 = false">关 闭</el-button>
			</span>
		</el-dialog>

		<el-dialog title="消费记录" :visible.sync="dialogHistoryVisible2" width="1000px">
			<el-row>
				<el-col>
					<el-date-picker v-model="page2.date" type="date" placeholder="选择日期" size="small"
						@change="getHistory2()" value-format="yyyy-MM-dd">
					</el-date-picker>
					<el-select placeholder="选择类型" v-model="page2.type" @change="getHistory2()" size="small"
						style="margin-left: 10px;">
						<el-option value="0" label="短信"></el-option>
						<el-option value="1" label="语音"></el-option>
					</el-select>
				</el-col>
				<el-col class="content_margin_top">
					<el-table :data="tableDataHistory2" size="mini" stripe border
						:header-cell-style="{'text-align':'center','background':'#FAFAFA'}"
						:cell-style="{'text-align':'center'}">
						<el-table-column prop="tenantName" label="订单号">
						</el-table-column>
						<el-table-column prop="createTime" label="支付单号">
						</el-table-column>
						<el-table-column prop="createTime" label="充值类型" width="120px">
						</el-table-column>
						<el-table-column prop="createTime" label="支付类型" width="120px">
						</el-table-column>
						<el-table-column prop="createTime" label="支付金额" sortable width="150px">
						</el-table-column>
						<el-table-column prop="createTime" label="时间">
						</el-table-column>
					</el-table>
					<el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
						:current-page="page2.index" :page-sizes="[30, 50, 70, 90]" :page-size="page2.size"
						layout="total, sizes, prev, pager, next, jumper" :total="page2.total">
					</el-pagination>
				</el-col>
			</el-row>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogHistoryVisible2 = false">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import moment from 'moment'
	import service from '../../assets/api/service.js'
	import Breadcrumb from '../components/breadCrumb.vue'
	export default {
		components: {
			Breadcrumb
		},
		data() {
			return {
				bread: ['费用中心', '套餐余量'],
				tableData: {
					sms: 0,
					tel: 0,
					iotCardBalanceList: []
				},
				tableDataHistory1: [],
				tableDataHistory2: [],
				dialogHistoryVisible1: false,
				dialogHistoryVisible2: false,
				income: {
					activeUsers: "",
					paidUsers: "",
					income: "",
					incomeNumber: ''
				},
				page1: {
					date: '',
					total: 0,
					index: 1,
					size: 20
				},
				page2: {
					date: '',
					type: '0',
					total: 0,
					index: 1,
					size: 20
				},
			}
		},
		filters:{
			TimeParse(data,formatString){
				formatString=formatString||'YYY-MM-DD hh:mm:ss'
				return moment(data).format(formatString)
			}
		},
		mounted() {
			this.getserviceSurplus()
			let date = new Date()
			let month = ''
			if ((date.getMonth() + 1).toString().length === 1) {
				month = '0' + (date.getMonth() + 1)
			}
			this.page2.date = date.getFullYear() + '-' + month + '-' + date.getDate()
		},
		methods: {
			//获取套餐剩余量
			async getserviceSurplus() {
				await service.getserviceSurplus().then(res => {
					if (res.code === 200) {
						this.tableData = res.data
					} else {
						this.$message.error('获取套餐剩余量失败')
						return
					}
				}, () => {
					this.$message.error('获取套餐剩余量失败')
					return
				})
			},
			//充值记录
			async getHistory1() {
				this.tableDataHistory1 = []
				await service.getHistory1(this.page1).then(res => {
					if (res.code === 200) {
						this.dialogHistoryVisible1 = true
						this.tableDataHistory1 = res.data
						this.page1.total = res.total
					} else {
						this.$message.error('获取充值记录失败')
						return
					}
				}, () => {
					this.$message.error('获取充值记录失败')
					return
				})
			},
			//消费记录
			async getHistory2() {
				this.tableDataHistory2 = []
				await service.getHistory2(this.page2).then(res => {
					if (res.code === 200) {
						this.dialogHistoryVisible2 = true
						this.tableDataHistory2 = res.data
						this.page2.total = res.total
					} else {
						this.$message.error('获取消费记录失败')
						return
					}
				}, () => {
					this.$message.error('获取消费记录失败')
					return
				})
			},
			handleSizeChange1(val) {
				this.page1.size = val
				this.getHistory1()
			},
			handleCurrentChange1(val) {
				this.page1.index = val
				this.getHistory1()
			},
			handleSizeChange2(val) {
				this.page2.size = val
				this.getHistory2()
			},
			handleCurrentChange2(val) {
				this.page2.index = val
				this.getHistory2()
			},
			toServive() {
				this.$router.push('/Service')
			}
		}
	}
</script>

<style>
</style>
