/**
 * 筛选出连续的数字，并返回二维数组
 * @param {*} arr // 传入数组
 * @returns // 返回二维数组
 */

export function arrange(arr) {
  let result = [];
  let temp = [];
  arr
    .sort((a, b) => {
      return a - b;
    })
    .concat(Infinity)
    .reduce((pre, next) => {
      //无穷大的目的是为了 让最后一个数进入 result 数组  如果不加这回 出现 最后一个数字无法进入数组
      temp.push(pre);
      if (next - pre > 1) {
        result.push(temp);
        temp = [];
      }
      return next;
    });
  return result;
}
