import { request, get, post, patch, del } from "./request.js";

let api = {
  getPlatformVideo: "ddk-camera/cameras/list", // 获取平台监控列表
  getPlatformVideoDetail: "ddk-camera/cameras", // 查看平台监控列表详情
  addPlatformVideo: "ddk-camera/cameras", // 添加平台监控
  deletePlatformVideo: "/ddk-camera/cameras", // 删除平台监控
};

// 获取平台监控列表
function getPlatformVideo(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getPlatformVideo, params, configs);
}

// 查看平台监控列表详情
function getPlatformVideoDetail(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(`${api.getPlatformVideoDetail}/${params.gbId}`, "", configs);
}

// 添加平台监控
function addPlatformVideo(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json",
    },
  };
  return post(api.addPlatformVideo, params, configs);
}

// 删除平台监控
function deletePlatformVideo(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return del(`${api.deletePlatformVideo}/${params.gbId}`, "", configs);
}

export default {
  getPlatformVideo,
  getPlatformVideoDetail,
  addPlatformVideo,
  deletePlatformVideo,
};
