import {
	request,
	get,
	post,
	patch,
	put,
	del
} from './request.js'

let api = {
	signup: 'portal/rest/1.0/auth/signUp', //注册
	login: 'portal/rest/1.0/auth/signIn', //登录
	captcha: 'portal/rest/1.0/auth/captcha', //发送验证码
	forget: 'portal/rest/1.0/auth/forget', //忘记密码
	auth: 'portal/rest/1.0/auth', //刷新token
	pushHistory: 'portal/rest/1.0/device/history/push' //推送历史
}

// 登录
function login(params) {
	var configs = {
		headers: {
		}
	}
	return post(api.login, params, configs)
}

// 获取推送历史
function pushHistory(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token')
		}
	}
	return get(api.pushHistory, params, configs)
}

// 发送验证码
function captcha(params) {
	var configs = {
		headers: {
		}
	}
	return post(api.captcha, params, configs)
}

// 忘记密码
function auth(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('refreshToken'),
			'content-type':'application/json;charset=UTF-8'
		}
	}
	return put(api.auth, params, configs)
}


function guid() {
  function S4 () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return (
    S4() +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        S4() +
        S4()
  )
}
export default {
	login,
	captcha,
	guid,
	pushHistory,
	auth
}
