
import {
	request,
	get,
	post,
	patch
} from './request.js'

let api = {
	getMessageData: 'portal/rest/1.0/device/history/warn', //获取分组
}
// 获取设备资源
function getMessageData(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token')
		}
	}
	return get(api.getMessageData, params, configs)
}

export default {
	getMessageData
}
