
import {
	request,
	get,
	post,
	patch,
	del
} from './request.js'

let api = {
	getVideo: 'portal/rest/1.0/user/video', //获取摄像头
	editVideo: 'portal/rest/1.0/user/video', //编辑摄像头
	deleteVideo:'portal/rest/1.0/user/video/',//删除摄像头
}

// 获取摄像头
function getVideo(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token')
		}
	}
	return get(api.getVideo, params, configs)
}

// 编辑摄像头
function insertVideo(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
			'content-type':'application/json'
		}
	}
	return post(api.editVideo, params, configs)
}

// 编辑摄像头
function updateVideo(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
			'content-type':'application/json'
		}
	}
	return patch(api.editVideo, params, configs)
}

// 编辑摄像头
function deleteVideo(params) {
	var configs = {
		headers: {
			'token': window.localStorage.getItem('token'),
		}
	}
	return del(api.deleteVideo+params, '', configs)
}

export default {
	getVideo,
	insertVideo,
	updateVideo,
	deleteVideo
}
