<template>
  <div>
    <Breadcrumb :first="bread[0]" :second="bread[1]"></Breadcrumb>
    <el-row :gutter="10">
      <el-col :span="10">
        <el-card class="shadow-sm">
          <el-row>
            <el-col>
              <el-button size="mini" type="primary" @click="addGroup = true"
                >添加分组</el-button
              >
              <el-button
                size="mini"
                type="danger"
                :disabled="!hasSelected"
                @click="displayDeleteDialog('', 'many')"
                >批量删除
              </el-button>
            </el-col>
            <el-col class="content_margin_top">
              <h5>分组</h5>
            </el-col>
            <el-col class="content_margin_top">
              <el-table
                ref="dragGroupTable"
                :data="tableData"
                size="mini"
                stripe
                border
                :header-cell-style="{
                  background: '#f1f1f1',
                  'text-align': 'center',
                }"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @row-click="rowClick"
              >
                <el-table-column
                  type="selection"
                  :selectable="handleSelect"
                  width="55px"
                >
                </el-table-column>
                <el-table-column prop="name" label="分组名称">
                </el-table-column>
                <el-table-column align="center" label="移动" width="100px">
                  <template slot-scope="{}">
                    <i
                      class="el-icon-rank"
                      style="font-size:26px;cursor: pointer;"
                    ></i>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="260px">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      @click.native.stop="handleEditGroup(scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      type="danger"
                      size="mini"
                      @click.native.stop="
                        displayDeleteDialog(scope.row, 'unit')
                      "
                      :disabled="scope.row.defaultGroup"
                      >删除</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      @click="submitShare(scope.row)"
                      >分享分组</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="14">
        <el-card class="shadow-sm">
          <el-row>
            <el-col>
              <el-button
                size="mini"
                type="primary"
                @click="dialogFormVisible = true"
                >绑定新设备</el-button
              >
              <el-button
                size="mini"
                type="primary"
                @click="dialogMoreVisible = true"
                >批量绑定新设备</el-button
              >
            </el-col>
            <el-col class="content_margin_top">
              <h5>设备</h5>
            </el-col>
            <el-col class="content_margin_top">
              <el-table
                row-key="id"
                ref="dragTable"
                :data="$store.state.tableDataDevice"
                size="mini"
                border=""
                :header-cell-style="{
                  background: '#f1f1f1',
                  'text-align': 'center',
                }"
                :cell-style="{ 'text-align': 'center' }"
              >
                <el-table-column prop="ico" label="设备图标" width="120px">
                  <template slot-scope="scope">
                    <img
                      v-if="scope.row.ico != undefined"
                      :src="scope.row.ico"
                      style="width: 60px; height: 60px"
                      alt
                    />
                    <span v-else>暂无图标</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="id"
                  label="设备Id"
                  width="100px"
                ></el-table-column>
                <el-table-column prop="name" label="设备名称"></el-table-column>
                <el-table-column prop="online" label="设备状态" width="120px">
                  <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.online" size="mini"
                      >在线</el-tag
                    >
                    <el-tag type="danger" v-else size="mini">离线</el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="245px">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑
                    </el-button>
                    <el-button size="mini" @click="handleGroup(scope.row)"
                      >更新分组
                    </el-button>
                    <el-button
                      size="mini"
                      type="danger"
                      @click="displayUnBindDialog(scope.row)"
                      >解绑</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column label="移动" width="100">
                  <template slot-scope="{}">
                    <i
                      class="el-icon-rank"
                      style="font-size: 26px; cursor: pointer"
                    ></i>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog title="设备绑定" :visible.sync="dialogFormVisible" width="25%">
      <el-form
        :model="form"
        label-position="right"
        label-width="50px"
        :rules="rules"
        ref="addruleForm"
      >
        <el-form-item label="id" prop="id">
          <el-input v-model="form.id"></el-input>
        </el-form-item>
        <el-form-item label="sn" prop="sn">
          <el-input v-model="form.sn"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAddDevice('addruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="批量设备绑定"
      :visible.sync="dialogMoreVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="
        errorMoreList = [];
        $refs.addMoreRuleForm.resetFields();
      "
      width="35%"
    >
      <el-form
        :model="moreForm"
        label-position="right"
        label-width="80px"
        :rules="moreRules"
        ref="addMoreRuleForm"
      >
        <el-form-item label="设备信息" prop="info">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4 }"
            placeholder="请输入设备信息"
            v-model.trim="moreForm.info"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div v-if="errorMoreList.length > 0">
        <div
          v-for="(item, i) in errorMoreList"
          :key="i"
          :style="
            item && item.message === '绑定成功'
              ? 'color: green;'
              : item && item.message === '绑定失败'
              ? 'color: red;'
              : ''
          "
        >
          <span v-if="item">
            （{{ i + 1 }}） id：{{ item.id }} sn：{{ item.sn }}
            {{ item.message }}
          </span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogMoreVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handleAddMoreDevice('addMoreRuleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="设备信息修改"
      :visible.sync="updateFormVisible"
      width="400px"
    >
      <el-form
        :model="updateform"
        label-position="right"
        label-width="100px"
        :rules="rules"
        ref="updataruleForm"
      >
        <el-form-item label="设备名称" prop="name">
          <el-input v-model="updateform.name"></el-input>
        </el-form-item>
        <el-form-item label="设备图标" prop="img">
          <el-upload
            action
            :data="aliyunOssToken"
            :file-list="fileList"
            :http-request="uploadImg"
            list-type="picture"
            :limit="1"
            :on-exceed="handleOssList"
          >
            <el-button class="avatar-uploader-icon" type="primary"
              >选择图标</el-button
            >
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleEditDevice('updataruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="添加分组" :visible.sync="addGroup" width="40%">
      <el-form
        ref="addModelRef"
        :model="addDeviceGroup"
        label-width="80px"
        :rules="addModelRules"
      >
        <el-form-item label="分组名称" prop="name">
          <el-input v-model="addDeviceGroup.name" />
        </el-form-item>
        <el-form-item label="排序号" prop="sort">
          <el-input v-model="addDeviceGroup.sort" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handelAddGroup('addModelRef')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="修改分组信息"
      :visible.sync="updateGroupInfoVisible"
      width="40%"
    >
      <el-form
        ref="updateModelFormRef"
        :model="updateDeviceGroup"
        label-width="80px"
        :rules="addModelRules"
      >
        <el-form-item label="分组名称" prop="name">
          <el-input v-model="updateDeviceGroup.name"></el-input>
        </el-form-item>
        <el-form-item label="排序号" prop="sort">
          <el-input v-model="updateDeviceGroup.sort" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateGroupInfoVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleupdateGroup">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="设备解绑"
      :visible.sync="dialogUnBindVisible"
      width="300px"
    >
      确定解绑该设备吗
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogUnBindVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleUnBindDevice()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="删除分组" :visible.sync="deleteGroup" width="300px">
      确定删除该分组吗
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteGroup = false">取 消</el-button>
        <el-button type="primary" @click="handleDeleteGroup()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="更新设备分组"
      :visible.sync="updateGroupVisible"
      width="30%"
    >
      <el-form label-position="right" label-width="16%" :rules="rules">
        <el-form-item label="设备分组">
          <el-select v-model="groupName" placeholder="请选择设备分组">
            <el-option
              v-for="item in deviceGroupData"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateGroupVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateGrouptoGroup('updataruleGroup')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="更新设备分组"
      :visible.sync="updateGroupVisible"
      width="30%"
    >
      <el-form label-position="right" label-width="16%" :rules="rules">
        <el-form-item label="设备分组">
          <el-select v-model="groupName" placeholder="请选择设备分组">
            <el-option
              v-for="item in deviceGroupData"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateGroupVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateGrouptoGroup('updataruleGroup')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="分享设备" :visible.sync="dialogVisible" width="400px">
      <div
        style="width: 100%;height: 100%;display: flex;justify-content: center;"
        ref="id"
        id="divCode"
      ></div>
      <div style="width: 100%;text-align: center;margin-top: 30px;">
        <el-button type="primary" plain @click="downloadCode"
          >下载二维码</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import QRCode from "qrcodejs2";
import Sortable from "sortablejs";
import device from "../../assets/api/device.js";
import other from "../../assets/api/other.js";
import Breadcrumb from "../components/breadCrumb.vue";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      qrcode: "",
      loading: false,
      dialogVisible: false,
      deldeviceid: "",
      groupid: "",
      deleteid: "",
      bread: ["设备管理", "设备分组"],
      updateGroupSort: false,
      updateGroupVisible: false,
      dialogFormVisible: false,
      dialogMoreVisible: false,
      updateFormVisible: false,
      updateGroupInfoVisible: false,
      dialogUnBindVisible: false,
      deleteGroup: false,
      tableData: [],
      tableDataDevice: [],
      listQuery: {
        index: 1,
        size: 10,
      },
      total: 0,
      addGroup: false,
      addDeviceGroup: {
        name: "",
        sort: "",
      },
      updateDeviceGroup: {},
      addModelRules: {
        name: [
          {
            required: true,
            message: "请输入分组名称",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "请选择排序号",
            trigger: "change",
          },
        ],
      },
      errorMoreList: [],
      moreForm: {
        info: "",
      },
      moreRules: {
        info: [
          {
            required: true,
            message: "请输入设备信息",
            trigger: "blur",
          },
        ],
      },
      updateGroup: {},
      groupId: "",
      groupName: "",
      deviceGroupData: [],
      aliyunOssToken: {},
      fileList: [],
      updateform: {},
      multipleSelection: [],
      updateDeviceGroupSort: {},
      sortable: null,
      sortableDevice: null,
      oldList: [],
      newList: [],
      newListDevice: [],
      form: {
        img: "",
        id: "",
        sn: "",
      },
      rules: {
        id: [
          {
            required: true,
            message: "请输入id",
            trigger: "blur",
          },
        ],
        sn: [
          {
            required: true,
            message: "请输入sn",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入设备名称",
            trigger: "blur",
          },
        ],
        icon: [
          {
            required: true,
            message: "请上传图像",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    hasSelected() {
      return this.multipleSelection.length > 0;
    },
  },
  mounted() {
    this.getdeviceGroup();
  },
  methods: {
    rowClick(row) {
      this.getdeviceByGroup(row.id);
    },
    handleSelect(row) {
      if (row.defaultGroup) {
        return false;
      } else {
        return true;
      }
    },
    //设备排序效果
    setSort1() {
      const el = this.$refs.dragTable.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortableDevice = Sortable.create(el, {
        ghostClass: "sortable-ghost",
        setData: function(dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          const targetRow = this.$store.state.tableDataDevice.splice(
            evt.oldIndex,
            1
          )[0];
          this.$store.state.tableDataDevice.splice(evt.newIndex, 0, targetRow);
          const tempIndex = this.$store.state.newListDevice.splice(
            evt.oldIndex,
            1
          )[0];
          this.$store.state.newListDevice.splice(evt.newIndex, 0, tempIndex);
          this.handleDeviceSort();
        },
      });
    },

    // 更新设备排序
    async handleDeviceSort() {
      let data = [];
      this.$store.state.newListDevice.forEach((item, index) => {
        data.push({
          id: item,
          sort: index,
        });
      });
      await device.sortDevice(data).then(
        (res) => {
          if (res.code === 200) {
            this.$message.success("设备排序成功!");
            this.getdeviceByGroup(this.groupid);
          } else {
            this.$message.error("操作失败");
            return;
          }
        },
        () => {
          this.$message.error("操作失败");
          return;
        }
      );
    },
    //分组排序效果
    setSort() {
      const el = this.$refs.dragGroupTable.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost",
        setData: function(dataTransfer) {
          dataTransfer.setData("Text", "");
        },

        onEnd: (evt) => {
          const targetRow = this.tableData.splice(evt.oldIndex, 1)[0];
          this.tableData.splice(evt.newIndex, 0, targetRow);
          const tempIndex = this.newList.splice(evt.oldIndex, 1)[0];
          this.newList.splice(evt.newIndex, 0, tempIndex);
          this.handleGroupSort();
        },
      });
    },
    //更新分组排序
    async handleGroupSort() {
      let data = [];
      this.newList.forEach((item, index) => {
        data.push({
          id: item,
          sort: index,
        });
      });
      await device.sortGroup(data).then(
        (res) => {
          if (res.code === 200) {
            this.$message.success("分组排序成功!");
            this.getdeviceGroup();
          } else {
            this.$message.error("操作失败");
            return;
          }
        },
        () => {
          this.$message.error("操作失败");
          return;
        }
      );
    },
    //获取分组列表
    async getdeviceGroup() {
      this.tableData = [];
      await device.getdeviceGroup().then(
        (res) => {
          if (res.code === 200) {
            this.deviceGroupData = res.data;
            this.tableData = res.data;
            if (res.data) {
              let data = res.data.map((item) => {
                return item.id;
              });
              this.newList = data;
            }
            this.$nextTick(() => {
              this.setSort();
            });
          } else {
            this.$message.error("获取分组数据失败");
            return;
          }
        },
        () => {
          this.$message.error("获取分组数据失败");
          return;
        }
      );
    },
    //添加分组
    async handelAddGroup(addModelRef) {
      await this.$refs[addModelRef].validate((valid) => {
        if (!valid) return;
        device.insertGroup(this.addDeviceGroup).then((res) => {
          if (res.code === 200) {
            this.addGroup = false;
            this.$message.success("添加分组成功!");
            this.getdeviceGroup();
          } else {
            this.$message.error("添加分组失败");
            return;
          }
        });
      });
    },
    //修改分组信息
    async handleupdateGroup() {
      await this.$refs.updateModelFormRef.validate((valid) => {
        if (!valid) return;
        device.updateGroupInfo(this.updateDeviceGroup).then((res) => {
          if (res.code === 200) {
            this.updateGroupInfoVisible = false;
            this.$message.success("修改分组成功!");
            this.getdeviceGroup();
          } else {
            this.$message.error("修改分组失败");
            return;
          }
        });
      });
    },
    //获取分组数据
    handleEditGroup(row) {
      this.updateGroupInfoVisible = true;
      this.updateDeviceGroup = {
        ...row,
      };
    },
    //删除分组
    async handleDeleteGroup(row) {
      await device
        .removeGroup({
          ids: this.deleteid,
        })
        .then(
          (res) => {
            if (res.code === 200) {
              this.$message.success("删除成功!");
              this.deleteGroup = false;
              this.getdeviceGroup();
            } else {
              this.$message.error("删除失败");
              return;
            }
          },
          () => {
            this.$message.error("删除失败");
            return;
          }
        );
    },
    //显示删除分组弹窗
    displayDeleteDialog(info, type) {
      this.deleteGroup = true;
      if (type === "unit") {
        this.deleteid = info.id;
      } else if (type === "many") {
        for (let i in this.multipleSelection) {
          this.multipleSelection[i] = this.multipleSelection[i].id;
        }
        this.deleteid = this.multipleSelection.join(",");
      }
    },
    //显示解绑设备弹窗
    displayUnBindDialog(info) {
      this.dialogUnBindVisible = true;
      this.deldeviceid = info.id;
    },
    //通过分组id获取设备表格
    async getdeviceByGroup(id) {
      this.$store.state.groupid = id;
      this.groupid = id;
      await device
        .getdeviceByGroup({
          groupId: id,
        })
        .then(
          (res) => {
            if (res.code === 200) {
              this.$store.state.tableDataDevice = res.data;

              if (res.data) {
                let data = this.$store.state.tableDataDevice.map((item) => {
                  return item.id;
                });
                this.$store.state.newListDevice = data;
              }
              this.$nextTick(() => {
                this.setSort1();
              });
            } else {
              this.$message.error("获取设备失败");
              return;
            }
          },
          () => {
            this.$message.error("获取设备失败");
            return;
          }
        );
    },
    //绑定设备
    async handleAddDevice(formName) {
      await this.$refs[formName].validate(
        (valid) => {
          if (valid) {
            device.insertBindToGroup(this.form).then((res) => {
              if (res.code === 200) {
                this.$message.success("绑定成功!");
                this.dialogFormVisible = false;
                this.getdeviceByGroup(this.groupid);
              } else {
                this.$message.error("绑定失败");
                return;
              }
            });
          } else {
            this.$message.error("输入有误");
            return;
          }
        },
        () => {
          this.$message.error("绑定失败");
          return;
        }
      );
    },
    // 批量绑定设备
    handleAddMoreDevice(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        try {
          const list = this.moreForm.info.split("\n").map((m) => JSON.parse(m));
          for (let i = 0; i < list.length; i++) {
            const data = {
              id: list[i].Content.ID,
              sn: list[i].Content.SN,
            };
            device.insertBindToGroup(data).then((res) => {
              if (res.code === 200) {
                const successRes = {
                  message: "绑定成功",
                  ...data,
                };
                this.$set(this.errorMoreList, i, successRes);
              } else {
                const errorRes = {
                  message: "绑定失败",
                  ...data,
                };
                this.$set(this.errorMoreList, i, errorRes);
              }
            });
          }
          this.$refs[formName].resetFields();
          this.getdeviceByGroup(this.groupid);
        } catch {
          this.$message.error("输入格式错误");
        }
      });
    },
    //获取设备信息
    handleEdit(index, row) {
      if (row.permissions.dev) {
        this.updateform = row;
        this.updateFormVisible = true;
      } else {
        this.$message.error("你没有此权限!");
      }
    },
    //编辑设备
    async handleEditDevice(formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateFormVisible = false;
          let Data = {
            deviceId: this.updateform.id,
            name: this.updateform.name,
            ico: this.form.img,
          };
          device.updateDevice(Data).then(
            (res) => {
              console.log(res);
              if (res.code === 200) {
                this.$message.success("修改成功!");
                this.updateFormVisible = false;
                this.fileList = [];
                this.form.img = "";
                this.getdeviceByGroup(this.groupid);
              } else {
                this.$message.error("修改失败");
                return;
              }
            },
            () => {
              this.$message.error("修改失败");
              return;
            }
          );
        } else {
          this.$message.error("输入有误");
          return;
        }
      });
    },
    // 解绑设备
    async handleUnBindDevice() {
      await device.unBindDevice(this.deldeviceid).then(
        (res) => {
          if (res.code === 200) {
            this.$message.success("解绑成功");
            this.dialogUnBindVisible = false;
            this.getdeviceByGroup(this.groupid);
          } else {
            this.$message.error("解绑失败");
            return;
          }
        },
        () => {
          this.$message.error("解绑失败");
          return;
        }
      );
    },
    //获取所属分组信息
    handleGroup(row) {
      this.updateGroupVisible = true;
      this.updateGroup.id = row.id;
      this.groupName = this.groupid;
    },
    //更新分组
    async updateGrouptoGroup() {
      const body = {
        id: this.updateGroup.id,
        groupId: this.groupName,
      };
      if (body.groupId == "") {
        this.$message.error("更改分组不能为空");
      } else {
        await device.updateGrouptoGroup(body).then(
          (res) => {
            if (res.code !== 200) {
              this.$message.error("更改分组失败");
            } else {
              this.$message.success("更改分组成功");
              this.updateGroupVisible = false;
              this.getdeviceByGroup(this.groupid);
            }
          },
          () => {
            this.$message.error("更改分组失败");
          }
        );
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    uploadImg(file) {
      var _self = this;
      let imgType = file.file.type.split("/")[1].toLowerCase();
      if (imgType != "jpg" && imgType != "png" && imgType != "jpeg") {
        this.$message.error("请上传正确的图片类型");
        return;
      }
      other
        .getOSSToken()
        .then(function(res) {
          _self.aliyunOssToken = res.data;
          let filename = file.file.name; //md5对图片名称进行加密
          let keyValue = `${_self.aliyunOssToken.dir}/` + filename;
          // 组装formdata
          let formdata = new FormData();
          formdata.append("name", file.file.name);
          formdata.append("key", keyValue);
          formdata.append("policy", _self.aliyunOssToken.policy);
          formdata.append("OSSAccessKeyId", _self.aliyunOssToken.accessId);
          formdata.append("success_action_status", 200);
          formdata.append("signature", _self.aliyunOssToken.signature);
          formdata.append("file", file.file);
          _self
            .uploadOSS(formdata, _self.aliyunOssToken.host)
            .then(function(res) {
              _self.form.img = _self.aliyunOssToken.host + "/" + keyValue;
              _self.$message.success("上传成功");
            })
            .catch(function(error) {
              _self.$message.error("上传失败");
              console.log(error);
            });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    uploadOSS(formData, url) {
      const config = {
        headers: {
          "Content-Type":
            "multipart/form-data;boundary=" + new Date().getTime(),
        },
      };
      return axios.post(url, formData, config);
    },
    handleOssList(file, filelist) {
      this.$message.error("上传失败,超出上传数量最大限制");
    },
    //提交要分享的设备
    async submitShare(info) {
      this.loading = true;

      await device.groupShare(info.id).then(
        (res) => {
          if (res.code == 200) {
            if (res.data.token) {
              let data = "";
              data = {
                Content: {
                  shareCode: res.data.token,
                },
                Action: "sharedevice",
              };
              // console.log(JSON.stringify(data));
              this.shareCode = res.data.token;

              this.dialogVisible = true;
              setTimeout(() => {
                this.createQrcode(JSON.stringify(data));
              }, 500);
            } else {
              this.$message.error("分享设备失败");
              return;
            }
          } else {
            this.$message.error("分享设备失败");
            return;
          }
        },
        (err) => {
          this.$message.error("分享设备失败");
          return;
        }
      );
    },
    createQrcode(code) {
      if (this.qrcode) {
        // 有新的二维码地址了，先把之前的清除掉
        this.$refs.id.innerHTML = "";
      }
      this.qrcode = new QRCode(this.$refs.id, {
        text: code,
        width: "150", // 二维码宽度 （不支持100%）
        height: "150", // 二维码高度 （不支持100%）
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.Q,
      });
    },
    //下载二维码
    downloadCode() {
      let date = `${new Date().toLocaleString()}`;
      var base64 = document.getElementById("divCode").children[1].src;
      var byteCharacters = atob(
        base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
      );
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], {
        type: undefined,
      });
      var aLink = document.createElement("a");
      aLink.download = `分组分享二维码 ${date}.jpg`; //这里写保存时的图片名称
      aLink.href = URL.createObjectURL(blob);
      aLink.click();
    },
  },
};
</script>

<style>
.btn-group {
  margin-bottom: 10px !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
}

.el-card__body {
  padding-bottom: 10px !important;
}

h5,
h4 {
  margin: 0px !important;
}
</style>
