<template>
	<div>
		<Breadcrumb :first='bread[0]' :second="bread[1]"></Breadcrumb>
		<el-card class="shadow-sm">
			<el-tabs>
				<el-tab-pane label="提交反馈">
					<div style="padding:10px;margin-bottom:10px;">
						致力为您提供高品质、专业的平台服务,对我们的服务有任何意见都可以提出，我们将尽快回复
					</div>
					<el-form style="width:60% !important" ref="ruleForm" :model="form" label-width="80px" :rules="rules">
						<el-form-item label="选择标签" prop="tag">
							<el-radio-group v-model="form.tag">
								<el-radio label="鼓励"></el-radio>
								<el-radio label="设备问题"></el-radio>
								<el-radio label="功能建议"></el-radio>
								<el-radio label="内容意见"></el-radio>
								<el-radio label="我要投诉"></el-radio>
								<el-radio label="其他"></el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="建议内容" prop="content">
							<el-input type="textarea" v-model="form.content" :autosize="{ minRows: 6, maxRows: 8 }">
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
							<el-button @click="resetForm('ruleForm')">重置</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
			
				<el-tab-pane label="反馈列表">
					<el-table :data="tableData" style="width: 100%; border-radius: 10px 10px 0 0;margin-top:10px "
						:header-cell-style="{ background: '#f4f4f4' }">
						<el-table-column prop="tag" label="标签" align="center">
						</el-table-column>
						<el-table-column prop="content" label="反馈内容" align="center">
						</el-table-column>
						<el-table-column label="反馈时间" align="center">
							<template slot-scope="scope">{{
			      scope.row.submitDate | utcMoment
			    }}</template>
						</el-table-column>
						<el-table-column prop="handledContent" label="回访内容" align="center">
						</el-table-column>
						<el-table-column label="回访时间" align="center">
							<template slot-scope="scope">{{
			      scope.row.handledDate | utcMoment
			    }}</template>
						</el-table-column>
					</el-table>
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="listQuery.index" :page-sizes="[20, 40, 60, 80]" :page-size="listQuery.size"
						layout="total, sizes, prev, pager, next, jumper" :total="listQuery.total">
					</el-pagination>
				</el-tab-pane>
			</el-tabs>
		</el-card>
	</div>
</template>

<script>
	import Breadcrumb from '../components/breadCrumb.vue'
	import user from "../../assets/api/user.js";
	import moment from "moment";
	export default {
		components: {
			Breadcrumb
		},
		data() {
			return {
				bread:['意见反馈',''],
				rules: {
					tag: [{
						required: true,
						message: "请至少选择一个标签",
						trigger: "change"
					}],
					content: [{
						required: true,
						message: "请填写反馈内容",
						trigger: "blur"
					}]
				},
				form: {
					content: "web平台意见反馈:",
					tag: ""
				},
				tableData: [],
				listQuery: {
					index: 1,
					size: 10,
					total:0
				}
			};
		},

		filters: {
			// utc 转  本地
			utcMoment: function(d) {
				console.log(d);
				if (!d) {
					return "--";
				}
				var i = -(new Date().getTimezoneOffset() / 60);
				var len = new Date(d).getTime();
				var utcTime = len;
				return moment(new Date(utcTime + 3600000 * i)).format(
					"YYYY-MM-DD HH:mm:ss"
				);
			}
		},

		mounted() {
			this.handlefeedback();
		},
		methods: {
			//获取反馈表格
			handlefeedback() {
				user.FeedbackList(this.listQuery).then(res => {
					if(res.code===200){
						this.tableData = res.data;
						this.listQuery.total = res.total;
					}
					else{
						this.$message.error('获取反馈数据失败')
						return
					}
				},()=>{
					this.$message.error('获取反馈数据失败')
					return
				});
			},

			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						user.Feedback(this.form).then(res => {
							this.$message({
								message: "提交成功",
								type: "success"
							});
							this.resetForm("ruleForm");
							this.handlefeedback();
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			handleSizeChange(val){
				this.listQuery.size=val
				this.handlefeedback()
			},
			handleCurrentChange(val){
					this.listQuery.index=val
					this.handlefeedback()
			}
		}
	};
</script>

<style lang="scss"></style>
