<template>
	<div>
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item class="text-12">{{first}}</el-breadcrumb-item>
		  <el-breadcrumb-item class="text-12">{{second}}</el-breadcrumb-item>
		</el-breadcrumb>
	</div>
</template>

<script>
	export default{
		props:['first','second']
	}
</script>

<style scoped>
	
</style>
