import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/element.js";
import Print from "vue-print-nb";
import store from "./assets/api/store.js";

import md5 from "md5";
Vue.prototype.$md5 = md5;

Vue.use(Print);

import qs from "qs";
Vue.prototype.qs = qs;
import "./utils/drag.js";

const preventReClick = Vue.directive("preventReClick", {
  inserted: function(el, binding) {
    el.addEventListener("click", () => {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 3000);
      }
    });
  },
});
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

var vue = new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

export default {
  vue,
  preventReClick,
};
