import vue from 'vue'
import vuex from 'vuex'

vue.use(vuex)
export default new vuex.Store({
	state:{
		deviceRow:{
			permissions:{
				conf:false
			}
		},
		tableDataDevice:[],
		newListDevice:[],
		tableDataResource:[],
		deviceid:'',
		groupid:'',
	}
})