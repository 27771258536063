import Vue from "vue";
import VueRouter from "vue-router";
import loginapi from "../assets/api/login.js";
import cookie from "../assets/cookie.js";
import Login from "../components/Login.vue";
import Index from "../components/Index.vue";
import Home from "../components/Home/Home.vue";
import DeviceList from "../components/Device/DeviceList.vue";
import DeviceGroup from "../components/Device/DeviceGroup.vue";
import DeviceShare from "../components/Device/DeviceShare.vue";
import ResourceSort from "../components/Device/ResourceSort.vue";
import ResourceHide from "../components/Device/ResourceHide.vue";
import Open from "../components/Other/Open.vue";
import Camera from "../components/Camera/Camera.vue";
import PlatformCamera from "../components/PlatformCamera/PlatformCamera.vue";
import Message from "../components/Message/Message.vue";
import ServiceSurplus from "../components/Service/ServiceSurplus.vue";
import Service from "../components/Service/Service.vue";
import User from "../components/Other/User.vue";
import FeekBack from "../components/Other/FeekBack.vue";
import Register from "../components/Other/Register.vue";
import Forget from "../components/Other/Forget.vue";

import "../assets/css/base.less";
import "../assets/css/specail.less";
import "../assets/css/font.css";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/Home",
  },
  {
    path: "/Login",
    component: Login,
  },
  {
    path: "/Forget",
    component: Forget,
  },
  {
    path: "/Register",
    component: Register,
  },
  {
    path: "/Index",
    component: Index,
    children: [
      {
        path: "/",
        redirect: "/Home",
      },
      {
        path: "/Home",
        component: Home,
        meta: {
          title: "首页",
        },
      },
      {
        path: "/Camera",
        component: Camera,
        meta: {
          title: "摄像头管理",
        },
      },
      {
        path: "/PlatformCamera",
        component: PlatformCamera,
        meta: {
          title: "平台监控管理",
        },
      },
      {
        path: "/DeviceList",
        component: DeviceList,
        meta: {
          title: "设备列表",
        },
      },
      {
        path: "/DeviceGroup",
        component: DeviceGroup,
        meta: {
          title: "设备分组",
        },
      },
      {
        path: "/DeviceShare",
        component: DeviceShare,
        meta: {
          title: "设备分享",
        },
      },
      {
        path: "/ResourceSort",
        component: ResourceSort,
        meta: {
          title: "资源排序",
        },
      },
      {
        path: "/ResourceHide",
        component: ResourceHide,
        meta: {
          title: "资源隐藏",
        },
      },
      {
        path: "/Open",
        component: Open,
        meta: {
          title: "开放接口",
        },
      },
      {
        path: "/Message",
        component: Message,
        meta: {
          title: "消息服务",
        },
      },
      {
        path: "/ServiceSurplus",
        component: ServiceSurplus,
        meta: {
          title: "套餐余量",
        },
      },
      {
        path: "/Service",
        component: Service,
        meta: {
          title: "套餐充值",
        },
      },
      {
        path: "/User",
        component: User,
        meta: {
          title: "用户信息",
        },
      },
      {
        path: "/FeekBack",
        component: FeekBack,
        meta: {
          title: "意见反馈",
        },
      },
    ],
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  // if (to.path === "/Login") {
  //   next();
  // }

  const hasToken = window.localStorage.getItem("token");

  if (hasToken) {
    if (to.path === "/Login") {
      next("/");
    } else {
      next();
    }
  } else {
    if (
      to.path !== "/Login" &&
      to.path !== "/Forget" &&
      to.path !== "/Register"
    ) {
      next("/Login");
    } else {
      next();
    }
  }
});

export default router;
