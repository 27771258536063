<template>
  <div>
    <Breadcrumb :first="bread[0]" :second="bread[1]"></Breadcrumb>
    <el-card class="shadow-sm">
      <el-button size="mini" type="primary" @click="addVideo = true">
        添加平台监控
      </el-button>
    </el-card>

    <el-card class="shadow-sm">
      <el-table
        :data="tableData"
        size="mini"
        stripe
        border
        style="width: 1200px;"
        :header-cell-style="{ background: '#F3F3F3', 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="name"
          label="摄像头名称"
          width="160px"
        ></el-table-column>
        <el-table-column
          prop="gbId"
          label="国标ID"
          width="200px"
        ></el-table-column>
        <el-table-column label="摄像头状态" width="120px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.cameraState === 0" type="info">
              未注册
            </el-tag>
            <el-tag v-else-if="scope.row.cameraState === 1" type="warning">
              锁定
            </el-tag>
            <el-tag v-else-if="scope.row.cameraState === 2" type="danger">
              离线
            </el-tag>
            <el-tag v-else-if="scope.row.cameraState === 3" type="success">
              在线
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="推流状态" width="120px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.streamState === 0" type="info">
              未推流
            </el-tag>
            <el-tag v-else-if="scope.row.streamState === 1" type="success">
              推流中
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="更新时间"
          width="160px"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="160px"
        ></el-table-column>
        <el-table-column label="播放" width="80px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-video-play"
              @click="handleVideo(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleLookDetail(scope.row)"
            >
              查看
            </el-button>
            <el-button
              type="danger"
              size="mini"
              @click="displayDeleteDialog(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="listQuery.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="listQuery.total"
      >
      </el-pagination>
    </el-card>

    <el-dialog title="添加平台监控" :visible.sync="addVideo" width="500px">
      <el-form
        ref="addModelRef"
        :model="addModalVideo"
        label-width="80px"
        :rules="addModelRules"
      >
        <el-form-item label="监控名称" prop="title">
          <el-input v-model="addModalVideo.title" />
        </el-form-item>
        <!-- <el-form-item label="地址">
          <el-input v-model="addModalVideo.url" />
        </el-form-item> -->
        <el-form-item label="设备" prop="deviceId">
          <el-select placeholder="请选择设备" v-model="addModalVideo.deviceId">
            <el-option
              v-for="item in deviceList"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传封面" prop="img">
          <el-upload
            action
            ref="uploadInsert"
            :data="aliyunOssToken"
            :http-request="uploadImg"
            list-type="picture"
            :limit="1"
            :on-exceed="handleOssList"
          >
            <el-button class="avatar-uploader-icon" type="primary" plain>
              选择封面
            </el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handelAddVideo('addModelRef')">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="播放"
      :visible.sync="playDialogVisible"
      width="1300px"
      @close="closeVideo"
      @open="showVideo = true"
      @opened="videPlay()"
    >
      <el-row>
        <el-col :span="12">
          <h5>{{ cameraName }}</h5>
        </el-col>
        <el-col
          :span="24"
          class="content_margin_top"
          style="height: 400px !important;"
        >
          <video
            id="my-video"
            ref="videoRef"
            class="video-js vjs-default-skin vjs-big-play-centered vjs-fluid"
            v-if="showVideo === true"
            preload="auto"
            :autoplay="true"
            muted="muted"
            controls
            style="width: 100%;min-height: 400px;object-fit: contain;"
          >
            <source :src="videoUrl" type="application/x-mpegURL rtmp/hd" />
          </video>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="playDialogVisible = false">关 闭</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="查看详情"
      :visible.sync="lookDetailDialogVisible"
      width="1200px"
    >
      <p style="display: flex; align-items: center">
        <span style="margin-right: 10px;">
          国标ID：{{ lookDetailRow.username }}
        </span>
        <el-link
          v-if="lookDetailRow.username"
          type="primary"
          icon="el-icon-document-copy"
          @click="createCopyText(lookDetailRow.username)"
        >
          复制
        </el-link>
      </p>
      <p style="display: flex; align-items: center">
        <span style="margin-right: 10px;">
          密码：{{ lookDetailRow.password }}
        </span>
        <el-link
          v-if="lookDetailRow.password"
          type="primary"
          icon="el-icon-document-copy"
          @click="createCopyText(lookDetailRow.password)"
        >
          复制
        </el-link>
      </p>
      <p style="display: flex; align-items: center">
        <span style="margin-right: 10px;">
          监控flv直播地址：{{ lookDetailRow.flvLiveUrl }}
        </span>
        <el-link
          v-if="lookDetailRow.flvLiveUrl"
          type="primary"
          icon="el-icon-document-copy"
          @click="createCopyText(lookDetailRow.flvLiveUrl)"
        >
          复制
        </el-link>
      </p>
      <p style="display: flex; align-items: center">
        <span style="margin-right: 10px;">
          监控hls直播地址：{{ lookDetailRow.hlsLiveUrl }}
        </span>
        <el-link
          v-if="lookDetailRow.hlsLiveUrl"
          type="primary"
          icon="el-icon-document-copy"
          @click="createCopyText(lookDetailRow.hlsLiveUrl)"
        >
          复制
        </el-link>
      </p>
      <p style="display: flex; align-items: center">
        <span style="margin-right: 10px;">
          监控rtmp直播地址：{{ lookDetailRow.rtmpLiveUrl }}
        </span>
        <el-link
          v-if="lookDetailRow.rtmpLiveUrl"
          type="primary"
          icon="el-icon-document-copy"
          @click="createCopyText(lookDetailRow.rtmpLiveUrl)"
        >
          复制
        </el-link>
      </p>
    </el-dialog>

    <el-dialog
      title="删除确认"
      :visible.sync="deleteDialogVisible"
      width="200px"
    >
      确定删除该平台监控吗
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleDelete">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
var player;
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "videojs-contrib-hls";
import axios from "axios";
import other from "../../assets/api/other.js";
import camera from "../../assets/api/camera.js";
import platformcamera from "../../assets/api/platformcamera.js";
import device from "../../assets/api/device.js";
import Breadcrumb from "../components/breadCrumb.vue";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      videooption: {
        bigPlayButton: false,
        textTrackDisplay: false,
        posterImage: false,
        errorDisplay: false,
        controlBar: {
          volumePanel: {
            inline: false, // 默认是true,横着的
          },
          children: [
            {
              name: "playToggle",
            }, // 播放按钮
            {
              name: "liveDisplay",
            },
            {
              name: "volumePanel", // 音量控制
              inline: false, // 不使用水平方式
            },
            {
              name: "FullscreenToggle",
            }, // 全屏
          ],
        },
      },
      player: "",
      showVideo: false,
      cameraName: "",
      videoUrl: "",
      videoid: "",
      deviceList: [],
      lookDetailDialogVisible: false,
      deleteDialogVisible: false,
      playDialogVisible: false,
      lookDetailRow: {},
      bread: ["摄像头", ""],
      aliyunOssToken: {},
      tableData: [],
      listQuery: {
        name: "",
        index: 1,
        size: 10,
        total: 0,
      },
      total: 0,
      addVideo: false,
      addModalVideo: {
        title: "",
        url: "",
        deviceId: "",
        img: "",
      },
      addModelRules: {
        title: [
          {
            required: true,
            message: "请输入监控名称",
            trigger: "blur",
          },
        ],
        url: [
          {
            required: true,
            message: "请输入链接",
            trigger: "change",
          },
        ],
        deviceId: [
          {
            required: true,
            message: "请选择设备",
            trigger: "change",
          },
        ],
        img: [
          {
            message: "请上传封面",
            trigger: "change",
          },
        ],
      },
      form: {
        img: "",
      },
    };
  },
  mounted() {
    this.getPlatformVideo();
    this.getdeviceByGroup();
  },
  methods: {
    //获取摄像头列表
    async getPlatformVideo() {
      const params = {
        name: this.listQuery.name,
        index: this.listQuery.index,
        size: this.listQuery.size,
      };
      await platformcamera.getPlatformVideo(params).then(
        (res) => {
          console.log(res);
          if (res.code === 0) {
            this.tableData = res.data;
            this.listQuery.total = res.total;
          } else {
            this.$message.error("获取摄像头数据失败");
            return;
          }
        },
        () => {
          this.$message.error("获取摄像头数据失败");
          return;
        }
      );
    },
    //获取设备列表
    async getdeviceByGroup() {
      this.tableData = [];
      await device.getdeviceByGroup().then(
        (res) => {
          if (res.code !== 200) {
            this.$message.error("获取设备数据失败");
            return;
          } else {
            this.deviceList = res.data;
          }
        },
        () => {
          this.$message.error("建立连接失败");
        }
      );
    },
    //添加摄像头
    async handelAddVideo(addModelRef) {
      this.$refs[addModelRef].validate(async (valid) => {
        if (!valid) return;
        await platformcamera
          .addPlatformVideo({ name: this.addModalVideo.title })
          .then(
            async (res) => {
              if (res.code === 0) {
                await platformcamera
                  .getPlatformVideoDetail({ gbId: res.message })
                  .then(
                    async (res2) => {
                      if (res2.code === 0) {
                        this.addModalVideo.url = res2.data.hlsLiveUrl;
                        this.addModalVideo.img = this.form.img;
                        await camera.insertVideo(this.addModalVideo).then(
                          (res3) => {
                            if (res3.code === 200) {
                              this.$message.success("添加摄像头成功!");
                              this.$refs.uploadInsert.clearFiles();
                              this.addVideo = false;
                              this.form.img = "";
                              this.addModalVideo = {
                                title: "",
                                url: "",
                                deviceId: "",
                                img: "",
                              };
                              this.getPlatformVideo();
                            } else {
                              this.$message.error("添加失败");
                              return;
                            }
                          },
                          () => {
                            this.$message.error("添加失败");
                            return;
                          }
                        );
                      } else {
                        this.$message.error("获取视频链接失败");
                        return;
                      }
                    },
                    () => {
                      this.$message.error("获取视频链接失败");
                      return;
                    }
                  );
              } else {
                this.$message.error("获取国标ID失败");
                return;
              }
            },
            () => {
              this.$message.error("获取国标ID失败");
              return;
            }
          );
      });
    },
    // 显示查看详情弹窗
    async handleLookDetail(info) {
      this.lookDetailRow = {};
      this.lookDetailDialogVisible = true;
      await platformcamera.getPlatformVideoDetail({ gbId: info.gbId }).then(
        (res) => {
          if (res.code === 0) {
            this.lookDetailRow = res.data || {};
          } else {
            this.$message.error("获取详情失败");
            return;
          }
        },
        () => {
          this.$message.error("获取详情失败");
          return;
        }
      );
    },
    //显示删除弹窗
    displayDeleteDialog(info) {
      this.deleteDialogVisible = true;
      this.videoid = info.gbId;
    },
    //删除摄像头
    async handleDelete() {
      await platformcamera.deletePlatformVideo({ gbId: this.videoid }).then(
        (res) => {
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.deleteDialogVisible = false;
            this.getPlatformVideo();
          } else {
            this.$message.error("删除失败");
            return;
          }
        },
        () => {
          this.$message.error("删除失败");
          return;
        }
      );
    },
    //封面上传
    uploadImg(file) {
      var _self = this;
      let imgType = file.file.type.split("/")[1].toLowerCase();
      if (imgType != "jpg" && imgType != "png" && imgType != "jpeg") {
        this.$message.error("请上传正确的图片类型");
        return;
      }
      other
        .getOSSToken()
        .then(function(res) {
          _self.aliyunOssToken = res.data;

          let filename = file.file.name; //md5对图片名称进行加密
          let keyValue = `${_self.aliyunOssToken.dir}/` + filename;

          // 组装formdata
          let formdata = new FormData();
          formdata.append("name", file.file.name);
          formdata.append("key", keyValue);
          formdata.append("policy", _self.aliyunOssToken.policy);
          formdata.append("OSSAccessKeyId", _self.aliyunOssToken.accessId);
          formdata.append("success_action_status", 200);
          formdata.append("signature", _self.aliyunOssToken.signature);
          formdata.append("file", file.file);

          _self
            .uploadOSS(formdata, _self.aliyunOssToken.host)
            .then(function(res) {
              _self.form.img = _self.aliyunOssToken.host + "/" + keyValue;
              _self.$message.success("上传成功");
            })
            .catch(function(error) {
              _self.$message.error("上传失败");
            });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    uploadOSS(formData, url) {
      const config = {
        headers: {
          "Content-Type":
            "multipart/form-data;boundary=" + new Date().getTime(),
        },
      };
      return axios.post(url, formData, config);
    },
    handleOssList(file, filelist) {
      console.log(file);
      this.$message.error("上传失败,超出上传数量最大限制");
    },
    //播放
    async handleVideo(row) {
      this.cameraName = row.name;
      this.playDialogVisible = true;
      await platformcamera.getPlatformVideoDetail({ gbId: row.gbId }).then(
        (res) => {
          if (res.code === 0) {
            this.videoUrl = res.data.hlsLiveUrl;
          } else {
            this.$message.error("获取视频链接失败");
            return;
          }
        },
        () => {
          this.$message.error("获取视频链接失败");
          return;
        }
      );
    },
    handleSizeChange(val) {
      this.listQuery.size = val;
      this.getPlatformVideo();
    },
    handleCurrentChange(val) {
      this.listQuery.index = val;
      this.getPlatformVideo();
    },
    playVideo() {
      player = videojs("my-video", this.videooption, function() {
        this.play();
      });
    },
    //弹窗打开后延迟播放视频
    videPlay() {
      let _this = this;
      setTimeout(function() {
        _this.playVideo();
      }, 500);
    },
    closeVideo() {
      player.dispose();
      this.showVideo = false;
    },
    createCopyText(text) {
      const res = _copy(text);
      if (res) {
        this.$message.success("已复制到剪贴板");
      } else {
        this.$message.error("复制失败");
      }
      function _copy(value) {
        const textArea = document.createElement("textarea");
        textArea.value = value;
        document.body.appendChild(textArea);
        textArea.select();
        const copyResult = document.execCommand("copy");
        textArea.remove();
        return copyResult;
      }
    },
  },
  // destroyed() {
  // 	player.dispose()
  // }
};
</script>

<style>
.btn-group {
  margin-bottom: 10px !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
}

.el-card__body {
  padding-bottom: 10px !important;
}

h5,
h4 {
  margin: 0px !important;
}
.vjs-fluid {
  padding-top: 0px !important;
}
</style>
