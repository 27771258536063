<template>
  <div>
    <Breadcrumb :first="bread[0]" :second="bread[1]"></Breadcrumb>
    <el-row :gutter="10">
      <el-col :span="24" style="padding-top: 1rem;">
        <el-button
          type="primary"
          :disabled="dataArr.length == 0"
          @click="submitShare"
          :loading="loading"
          >分享设备</el-button
        ></el-col
      >
      <el-col :span="24">
        <el-card class="shadow-sm">
          <el-row>
            <el-col class="content_margin_top">
              <el-table
                ref="tableDevice"
                :data="$store.state.tableDataDevice"
                size="mini"
                stripe
                border
                :header-cell-style="{
                  background: '#f1f1f1',
                  'text-align': 'center',
                }"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection"></el-table-column>
                <el-table-column prop="id" label="设备id"> </el-table-column>
                <el-table-column prop="name" label="设备名称">
                </el-table-column>
                <el-table-column label="设备状态">
                  <template slot-scope="scope">
                    <el-tag
                      type="success"
                      size="mini"
                      v-if="scope.row.online == true"
                      >在线</el-tag
                    >
                    <el-tag
                      type="danger"
                      size="mini"
                      v-if="scope.row.online == false"
                      >离线</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="onClickSettingOpen(scope.row.id)"
                    >
                      设置
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog
      title="分享设备"
      :visible.sync="dialogVisible"
      width="400px"
      :before-close="closeDialog"
    >
      <div v-if="shareCode != ''">
        <div
          style="width: 100%;height: 100%;display: flex;justify-content: center;"
          ref="id"
          id="divCode"
        ></div>
        <div style="width: 100%;text-align: center;margin-top: 30px;">
          <el-button type="primary" plain @click="downloadCode"
            >下载二维码</el-button
          >
        </div>
      </div>
      <div
        style="width: 100%;max-height: 500px;overflow-y: auto;"
        v-if="errorList.length != 0 || successList.length != 0"
      >
        <p
          style="width: 100%;text-align: center;"
          v-for="(item, index) in successList"
          :key="index"
        >
          id为
          <span style="color: #00c600;">{{ item.id }}</span>
          的设备分享成功！
        </p>
        <p
          style="width: 100%;text-align: center;"
          v-for="(item, index) in errorList"
          :key="index"
        >
          id为
          <span style="color: #ff0000;">{{ item.id }}</span>
          的设备分享失败！原因：
          <span style="color: #ff0000;">{{ codeMessage[item.errorCode] }}</span>
        </p>
      </div>
    </el-dialog>

    <el-dialog
      title="权限设置"
      :visible.sync="dialogAuthorityVisible"
      width="70%"
    >
      <div class="authority-setting">
        <div class="main-user">
          <span>主用户：</span>
          <el-button
            type="primary"
            size="mini"
            @click="onClickMainUserAuthSetting"
          >
            权限设置
          </el-button>
        </div>
        <div class="vice-user">
          <div>副用户：</div>
          <el-table
            v-loading="viceLoading"
            ref="viceTableDataRef"
            :data="
              viceTableData.slice(
                (currentPage - 1) * pagesize,
                currentPage * pagesize
              )
            "
            :current-page.sync="currentPage"
            size="mini"
            stripe
            border
            :header-cell-style="{
              background: '#f1f1f1',
              'text-align': 'center',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column prop="username" label="用户名"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="
                    onClickViceUserAuthSetting(
                      scope.row.deviceId,
                      scope.row.userId
                    )
                  "
                >
                  权限设置
                </el-button>
                <el-popconfirm
                  style="margin-left: 10px;"
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  icon="el-icon-info"
                  icon-color="red"
                  title="确认要解除绑定吗？"
                  @confirm="onConfirmUnBind(scope.row.id)"
                >
                  <el-button slot="reference" type="danger" size="mini">
                    解除绑定
                  </el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 15, 20]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
        width="40%"
        title="主用户权限设置"
        :visible.sync="innerMainUserAuthVisible"
        append-to-body
      >
        <div style="margin-top: 10px;">
          <span style="display: block;margin-bottom: 5px;">设备权限设置：</span>
          <el-checkbox-group v-model="mainUserDeviceCheckbox">
            <el-checkbox label="运行参数" border></el-checkbox>
            <el-checkbox label="设置参数" border></el-checkbox>
            <el-checkbox label="设备参数" border></el-checkbox>
          </el-checkbox-group>
        </div>
        <div style="margin-top: 20px;">
          <span style="display: block;margin-bottom: 5px;">报警权限设置：</span>
          <el-checkbox-group v-model="mainUserAlarmCheckbox">
            <el-checkbox label="短信推送" border></el-checkbox>
            <el-checkbox label="电话推送" border></el-checkbox>
            <el-checkbox label="报警推送" border></el-checkbox>
          </el-checkbox-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="medium" @click="innerMainUserAuthVisible = false">
            取 消
          </el-button>
          <el-button
            type="primary"
            size="medium"
            @click="onClickMainUserAuthSubmit"
          >
            确 定
          </el-button>
        </span>
      </el-dialog>
      <el-dialog
        width="40%"
        title="副用户权限设置"
        :visible.sync="innerViceUserAuthVisible"
        append-to-body
      >
        <div style="margin-top: 10px;">
          <span style="display: block;margin-bottom: 5px;">设备权限设置：</span>
          <el-checkbox-group v-model="viceUserDeviceCheckbox">
            <el-checkbox label="运行参数" border></el-checkbox>
            <el-checkbox label="设置参数" border></el-checkbox>
            <el-checkbox label="设备参数" border></el-checkbox>
          </el-checkbox-group>
        </div>
        <div style="margin-top: 20px;">
          <span style="display: block;margin-bottom: 5px;">报警权限设置：</span>
          <el-checkbox-group v-model="viceUserAlarmCheckbox">
            <el-checkbox label="短信推送" border></el-checkbox>
            <el-checkbox label="电话推送" border></el-checkbox>
            <el-checkbox label="报警推送" border></el-checkbox>
          </el-checkbox-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="medium" @click="innerViceUserAuthVisible = false">
            取 消
          </el-button>
          <el-button
            type="primary"
            size="medium"
            @click="onClickViceUserAuthSubmit"
          >
            确 定
          </el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { codeMessage } from "../../assets/api/request";
import QRCode from "qrcodejs2";
import device from "../../assets/api/device.js";
import Breadcrumb from "../components/breadCrumb.vue";
export default {
  components: {
    Breadcrumb,
  },
  computed: {
    codeMessage() {
      return codeMessage;
    },
  },
  data() {
    return {
      shareCode: "",
      errorList: [],
      successList: [],
      qrcode: "",
      loading: false,
      dialogVisibleError: false,
      dialogVisible: false,
      bread: ["设备分享", ""],
      tableHeight: "",
      dataArr: [],
      // 权限设置
      viceLoading: true,
      dialogAuthorityVisible: false,
      viceTableData: [],
      currentPage: 1,
      pagesize: 10,
      total: 0,
      // 主用户权限设置
      innerMainUserAuthVisible: false,
      mainUserDeviceCheckbox: [],
      mainUserAlarmCheckbox: [],
      // 设置主用户权限参数
      mainDeviceId: null,
      mainUserAuthObj: {},
      // 副用户权限设置
      innerViceUserAuthVisible: false,
      viceUserDeviceCheckbox: [],
      viceUserAlarmCheckbox: [],
      // 设置副用户权限参数
      deviceId: null,
      userId: null,
      viceUserAuthObj: {},
    };
  },
  mounted() {
    this.getDevice();

    this.$nextTick(function() {
      this.tableHeight =
        window.innerHeight - this.$refs.tableDevice.$el.offsetTop - 300;

      // 监听窗口大小变化
      let self = this;
      window.onresize = function() {
        self.tableHeight =
          window.innerHeight - self.$refs.tableDevice.$el.offsetTop - 300;
      };
    });
  },
  methods: {
    //通过分组获取设备信息
    async getDevice() {
      this.deviceOnline = "";
      await device.getdeviceByGroup().then(
        (res) => {
          if (res.code !== 200) {
            this.$message.error("获取设备数据失败");
            return;
          } else {
            this.$store.state.tableDataDevice = res.data;
          }
        },
        () => {
          this.$message.error("建立连接失败");
        }
      );
    },
    //多选事件
    handleSelectionChange(val) {
      this.dataArr = val;
    },
    //提交要分享的设备
    async submitShare() {
      this.loading = true;

      let params = [];
      this.dataArr.forEach((v) => {
        params.push({
          deviceId: v.id,
        });
      });

      await device.deviceShare(params).then(
        (res) => {
          if (res.code == 200) {
            this.loading = false;
            let data = "";
            if (res.data.token) {
              data = {
                Content: {
                  shareCode: res.data.token,
                },
                Action: "sharedevice",
              };
              this.shareCode = res.data.token;
            }
            this.dialogVisible = true;
            setTimeout(() => {
              this.createQrcode(JSON.stringify(data));
              console.log(data)
            }, 500);
            this.$refs.tableDevice.clearSelection();
            this.dataArr = [];

            if (res.data.deviceId) {
              this.errorList = [];
              this.successList = [];
              res.data.deviceId.forEach((v) => {
                if (!v.states) {
                  this.errorList.push(v);
                } else {
                  this.successList.push(v);
                }
              });
            }
          } else {
            this.$message.error("分享设备失败");
            this.loading = false;
            return;
          }
        },
        (err) => {
          this.$message.error("分享设备失败");
          this.loading = false;
          return;
        }
      );
    },
    createQrcode(code) {
      if (this.qrcode) {
        // 有新的二维码地址了，先把之前的清除掉
        this.$refs.id.innerHTML = "";
      }
      this.qrcode = new QRCode(this.$refs.id, {
        text: code,
        width: "150", // 二维码宽度 （不支持100%）
        height: "150", // 二维码高度 （不支持100%）
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.Q,
      });
    },
    //下载二维码
    downloadCode() {
      let date = `${new Date().toLocaleString()}`;
      var base64 = document.getElementById("divCode").children[1].src;
      var byteCharacters = atob(
        base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
      );
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], {
        type: undefined,
      });
      var aLink = document.createElement("a");
      aLink.download = `设备分享二维码 ${date}.jpg`; //这里写保存时的图片名称
      aLink.href = URL.createObjectURL(blob);
      aLink.click();
    },
    closeDialog() {
      this.shareCode = "";
      this.dialogVisible = false;
    },
    // 设置
    onClickSettingOpen(deviceId) {
      this.total = 0;
      this.viceTableData = [];
      this.mainDeviceId = deviceId;
      this.viceLoading = true;
      this.dialogAuthorityVisible = true;
      device.getViceUserSlave({ deviceId }).then(
        (res) => {
          this.viceLoading = false;
          this.viceTableData = res?.data || [];
          this.total = res?.data?.length || 0;
        },
        () => {
          this.viceLoading = false;
          this.viceTableData = [];
        }
      );
    },
    // 主用户权限设置
    onClickMainUserAuthSetting() {
      this.mainUserDeviceCheckbox = [];
      this.mainUserAlarmCheckbox = [];
      this.innerMainUserAuthVisible = true;
      const params = { deviceId: this.mainDeviceId };
      device.getMainUserAuthority(params).then((res) => {
        this.mainUserAuthObj = res.data || {};
        const tempAuth = [
          [
            () => res.data.run === true,
            () => {
              this.mainUserDeviceCheckbox.push("运行参数");
            },
          ],
          [
            () => res.data.conf === true,
            () => {
              this.mainUserDeviceCheckbox.push("设置参数");
            },
          ],
          [
            () => res.data.dev === true,
            () => {
              this.mainUserDeviceCheckbox.push("设备参数");
            },
          ],
          [
            () => res.data.sms === true,
            () => {
              this.mainUserAlarmCheckbox.push("短信推送");
            },
          ],
          [
            () => res.data.tel === true,
            () => {
              this.mainUserAlarmCheckbox.push("电话推送");
            },
          ],
          [
            () => res.data.warn === true,
            () => {
              this.mainUserAlarmCheckbox.push("报警推送");
            },
          ],
        ];
        tempAuth.forEach((item) => {
          if (!item[0]()) {
            return;
          }
          item[1]();
        });
      });
    },
    // 确认设置主用户权限
    onClickMainUserAuthSubmit() {
      const run = this.mainUserDeviceCheckbox.indexOf("运行参数") > -1;
      const conf = this.mainUserDeviceCheckbox.indexOf("设置参数") > -1;
      const dev = this.mainUserDeviceCheckbox.indexOf("设备参数") > -1;
      const sms = this.mainUserAlarmCheckbox.indexOf("短信推送") > -1;
      const tel = this.mainUserAlarmCheckbox.indexOf("电话推送") > -1;
      const warn = this.mainUserAlarmCheckbox.indexOf("报警推送") > -1;
      this.$set(this.mainUserAuthObj, "run", run);
      this.$set(this.mainUserAuthObj, "conf", conf);
      this.$set(this.mainUserAuthObj, "dev", dev);
      this.$set(this.mainUserAuthObj, "sms", sms);
      this.$set(this.mainUserAuthObj, "tel", tel);
      this.$set(this.mainUserAuthObj, "warn", warn);
      const params = {
        conf: this.mainUserAuthObj.conf,
        dev: this.mainUserAuthObj.dev,
        deviceId: this.mainUserAuthObj.deviceId,
        gzh: this.mainUserAuthObj.owner,
        id: this.mainUserAuthObj.id,
        run: this.mainUserAuthObj.run,
        sms: this.mainUserAuthObj.sms,
        tel: this.mainUserAuthObj.tel,
        userId: this.mainUserAuthObj.userId,
        warn: this.mainUserAuthObj.warn,
      };
      device.updateMainUserAuthority(params).then((res) => {
        if (res.code === 200) {
          this.$message.success("修改成功");
          this.innerMainUserAuthVisible = false;
        }
      });
    },
    // 副用户权限设置
    onClickViceUserAuthSetting(deviceId, userId) {
      this.deviceId = deviceId;
      this.userId = userId;
      this.viceUserDeviceCheckbox = [];
      this.viceUserAlarmCheckbox = [];
      this.innerViceUserAuthVisible = true;
      device.getViceUserAuthority({ deviceId, userId }).then((res) => {
        this.viceUserAuthObj = res.data || {};
        const tempAuth = [
          [
            () => res.data.run === true,
            () => {
              this.viceUserDeviceCheckbox.push("运行参数");
            },
          ],
          [
            () => res.data.conf === true,
            () => {
              this.viceUserDeviceCheckbox.push("设置参数");
            },
          ],
          [
            () => res.data.dev === true,
            () => {
              this.viceUserDeviceCheckbox.push("设备参数");
            },
          ],
          [
            () => res.data.sms === true,
            () => {
              this.viceUserAlarmCheckbox.push("短信推送");
            },
          ],
          [
            () => res.data.tel === true,
            () => {
              this.viceUserAlarmCheckbox.push("电话推送");
            },
          ],
          [
            () => res.data.warn === true,
            () => {
              this.viceUserAlarmCheckbox.push("报警推送");
            },
          ],
        ];
        tempAuth.forEach((item) => {
          if (!item[0]()) {
            return;
          }
          item[1]();
        });
      });
    },
    // 确认设置副用户权限
    onClickViceUserAuthSubmit() {
      const run = this.viceUserDeviceCheckbox.indexOf("运行参数") > -1;
      const conf = this.viceUserDeviceCheckbox.indexOf("设置参数") > -1;
      const dev = this.viceUserDeviceCheckbox.indexOf("设备参数") > -1;
      const sms = this.viceUserAlarmCheckbox.indexOf("短信推送") > -1;
      const tel = this.viceUserAlarmCheckbox.indexOf("电话推送") > -1;
      const warn = this.viceUserAlarmCheckbox.indexOf("报警推送") > -1;
      this.$set(this.viceUserAuthObj, "run", run);
      this.$set(this.viceUserAuthObj, "conf", conf);
      this.$set(this.viceUserAuthObj, "dev", dev);
      this.$set(this.viceUserAuthObj, "sms", sms);
      this.$set(this.viceUserAuthObj, "tel", tel);
      this.$set(this.viceUserAuthObj, "warn", warn);
      const params = {
        ...this.viceUserAuthObj,
        deviceId: this.deviceId,
        userId: this.userId,
      };
      device.updateViceUserAuthority(params).then((res) => {
        if (res.code === 200) {
          this.$message.success("修改成功");
          this.viceLoading = true;
          this.innerViceUserAuthVisible = false;
          device.getViceUserSlave({ deviceId: this.deviceId }).then(
            (res) => {
              this.viceLoading = false;
              this.viceTableData = res?.data || [];
              this.total = res?.data?.length || 0;
            },
            () => {
              this.viceLoading = false;
              this.viceTableData = [];
            }
          );
        }
      });
    },
    // 副用户解绑
    onConfirmUnBind(id) {
      device.unBindViceUser({ id }).then((res) => {
        if (res.code === 200) {
          this.$message.success("解绑成功");
          this.viceLoading = true;
          device.getViceUserSlave({ deviceId: this.deviceId }).then(
            (res) => {
              this.viceLoading = false;
              this.viceTableData = res?.data || [];
              this.total = res?.data?.length || 0;
            },
            () => {
              this.viceLoading = false;
              this.viceTableData = [];
            }
          );
        }
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
  },
  destroyed() {
    let state = this.$store.state;
    state.tableDataDevice = [];
  },
};
</script>

<style lang="less" scoped>
.el-card__body {
  padding-bottom: 10px !important;
}

.authority-setting {
  .main-user {
    margin-top: 10px;
  }
  .vice-user {
    margin-top: 20px;
  }
}
</style>
