import { get, post } from "./request.js";

let api = {
  getdeviceStatus: "portal/rest/1.0/device", //获取设备统计数据
  getuserDeviceStatus: "portal/rest/1.0/device/userDeviceStatus", // 用户的设备状态概览
};

// 获取设备状态
function getdeviceStatus(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getdeviceStatus, params, configs);
}

function getuserDeviceStatus(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getuserDeviceStatus, params, configs);
}

export default {
  getdeviceStatus,
  getuserDeviceStatus,
};
