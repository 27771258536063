import axios from "axios";
import router from "../../router";
import { Message } from "element-ui";
import login from "./login.js";

const codeMessage = {
  10001: "无法解析的MQCP数据",
  10002: "不支持的MQCP版本",
  10003: "不支持的MQCP命令字",
  10004: "不支持的请求头",
  10005: "不支持的MQCP路由",
  10006: "不支持的定时任务命令字",
  10007: "不支持的ModBus数据类型",
  10008: "不支持的网络枚举类型",

  10101: "设备不存在",
  10102: "设备不在线",
  10103: "设备繁忙",
  10104: "设备响应超时",
  10105: "无权操作该设备",
  10106: "设备ID与SN不匹配",
  10107: "已被封禁的设备",
  10108: "已被绑定的设备",
  10109: "设备绑定失败",
  10110: "设备解绑失败",
  10111: "分享的设备已过期",

  10201: "寄存器数量不能为0",
  10202: "寄存器地址无效",
  10203: "已被绑定的寄存器",
  10204: "寄存器不支持报警",

  10301: "无权操作该模板",

  20001: "用户名已存在",
  20002: "用户名或密码错误",
  20003: "用户名格式错误",
  20004: "验证码发送失败",
  20005: "验证码错误",
  10111: "分享的设备已过期",
  429: "频繁发送验证码!",

  30001: "支付失败",
  30002: "不支持的支付方式",
};

const request = axios.create({
  //  baseURL: '/api',

  baseURL: window.g.baseURL,
  timeout: 300000,
});

//设置token
request.setToken = (token, refreshToken) => {
  localStorage.setItem("token", token);
  localStorage.setItem("refreshToken", refreshToken);
};

axios.interceptors.request.use((config) => {
  // 取消请求
  //   removePending(config);
  console.log(config.url);
  if (config.url == "") {
    return config;
  } else {
    return config;
  }
});

request.interceptors.response.use(
  (response) => {
    const res = response.data;
    const _this = this;
    if (res.code === 401 && response.config.url !== "portal/rest/1.0/auth") {
      return login
        .auth()
        .then((res) => {
          //接收新的token跟refreshToken
          let token = res.data.token,
            refreshToken = res.data.refreshToken;
          // 设置新的token跟refreshToken
          request.setToken(token, refreshToken);
          const config = response.config;
          // 重置一下配置
          config.headers["token"] = token;
          return request(config);
        })
        .catch((res) => {
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("refreshToken");
          router.push({
            path: "/Login",
          });
          Message.warning("登录超时");
        });
    } else if (res.code !== 0 && res.code !== 200 && res.code !== 401) {
      Message.error(codeMessage[res.code] ?? "操作失败");
      return res;
    } else {
      return res;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
/**
 * @description: get请求
 * @param {string} url 请求链接段
 * @param {object} params 请求参数
 * @param {object} config 请求配置
 * @return {*}
 */
const get = (url, params, config) => {
  const reqData = {
    method: "get",
    url,
    params,
  };
  if (config) {
    Object.assign(reqData, config);
  }
  return request(reqData);
};

const del = (url, params, config) => {
  const reqData = {
    method: "delete",
    url,
    params,
  };
  if (config) {
    Object.assign(reqData, config);
  }
  return request(reqData);
};
/**
 * @description: post请求
 * @param {string} url 请求链接段
 * @param {object} params 请求参数
 * @param {object} config 请求配置
 * @return {*}
 */
const post = (url, data, config) => {
  const reqData = {
    method: "post",
    url,
    data,
  };
  if (config) {
    Object.assign(reqData, config);
  }
  return request(reqData);
};

const patch = (url, data, config) => {
  const reqData = {
    method: "patch",
    url,
    data,
  };
  if (config) {
    Object.assign(reqData, config);
  }
  return request(reqData);
};

const put = (url, data, config) => {
  const reqData = {
    method: "put",
    url,
    data,
  };
  if (config) {
    Object.assign(reqData, config);
  }
  return request(reqData);
};

const putget = (url, params, config) => {
  const reqData = {
    method: "put",
    url,
    params,
  };
  if (config) {
    Object.assign(reqData, config);
  }
  return request(reqData);
};

export { request, get, post, del, patch, put, putget, codeMessage };
