<template>
	<div>
		<Breadcrumb :first='bread[0]' :second="bread[1]"></Breadcrumb>
		<el-card class="shadow-sm">
			<el-button type="primary" size="mini" @click="handlePutOpen">添加</el-button>
		</el-card>
		<el-card class="shadow-sm">
			<el-table :data="tableData" size="mini" stripe border
				:header-cell-style="{ 'background': '#f1f1f1','text-align':'center'}"
				:cell-style="{'text-align':'center'}">
				<el-table-column prop="appId" label="appId" align="center" />
				<el-table-column prop="appKey" label="appKey" align="center" />
				<el-table-column prop="remark" label="备注 " align="center" />
				<el-table-column label="操作" align="center" width="300px">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="handlePort(scope.row)">转到开放接口</el-button>
						<el-button size="mini" type="danger" @click="displayDeleteVisible(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="listQuery.index" :page-sizes="[20, 40, 60, 80]" :page-size="listQuery.size"
				layout="total, sizes, prev, pager, next, jumper" :total="listQuery.total">
			</el-pagination>
		</el-card>
		<el-dialog title="添加" :visible.sync="addopenapi" width="30%" :append-to-body="true"
			@close="updateModelFormClose('addModelRef')">
			<el-form ref="addModelRef" :model="addModalVideo" label-width="80px" :rules="addModelRules">
				<el-form-item label="备注" prop="remark">
					<el-input v-model="addModalVideo.remark" />
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handelAddVideo('addModelRef')">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="删除" :visible.sync="dialogDeleteVisible" width="30%">
			确定删除吗？
			<span slot="footer" class="dialog-footer">
				<el-button type="info" @click="dialogDeleteVisible=false">关 闭</el-button>
				<el-button type="primary" @click="deleteRemark">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	// import { getOpenApiList, addOpenApi, deleteOpenApi } from "../../api/openapi";
	import Breadcrumb from '../components/breadCrumb.vue'
	import other from '../../assets/api/other.js'
	export default {
		components: {
			Breadcrumb
		},
		data() {
			return {
				appid: '',
				bread: ['开放接口', ''],
				tableData: [],
				listQuery: {
					total: 0,
					index: 1,
					size: 10,
				},
				addopenapi: false,
				dialogDeleteVisible: false,
				addModalVideo: {
					remark: "",
				},
				addModelRules: {
					remark: [{
						required: true,
						message: "请输入备注",
						trigger: "blur"
					}],
				},
			};
		},
		created() {
			this.getopenapi();
		},
		methods: {
			//获取开放列表
			async getopenapi() {
				await other.getopenapi(this.listQuery).then(res => {
					if (res.code === 200) {
						this.listQuery.total = res.total;
						if (res.data) {
							this.tableData = res.data;
							this.listQuery.total = res.total
						} else {
							this.tableData = [];
						}
					} else {
						this.$message.error('获取开放列表失败')
						return
					}
				}, () => {
					this.$message.error('获取开放列表失败')
					return
				});
			},
			updateModelFormClose(ref) {
				this.$refs[ref].resetFields();
			},

			handlePutOpen() {
				this.addopenapi = true;
			},

			//添加
			async handelAddVideo() {
				await other.insertNewApiRemark(this.addModalVideo).then((res) => {
					if (res.code == 200) {
						this.$message.success("添加成功!");
						this.addopenapi = false;
						this.getopenapi();
					} else {
						this.$message.error("添加失败!");
						return
					}
				}, () => {
					this.$message.error("添加失败!");
					return
				});
			},
			//显示删除弹窗
			displayDeleteVisible(info) {
				this.dialogDeleteVisible = true
				this.appid = info.appId
			},
			//删除
			async deleteRemark() {
				await other.deleteApiRemark({
					appId: this.appid
				}).then((res) => {
					if (res.code === 200) {
						this.$message.success("删除成功!");
						this.dialogDeleteVisible = false
						this.getopenapi();
					} else {
						this.$message.error('删除失败')
						return
					}
				}, () => {
					this.$message.error('删除失败')
					return
				});
			},
			handlePort(row) {
				console.log(row);
				// let appId = this.appId;
				// let appKey = this.appKey;
				window.open(
					"https://" +
					`${row.appId}:${row.appKey}` +
					"@openapi.devcld.com/swagger-ui.html",
					"_blank"
				);
			},
			handleSizeChange(val) {
				this.listQuery.size = val
				this.getopenapi()
			},
			handleCurrentChange(val) {
				this.listQuery.index = val
				this.getopenapi()
			}
		},
	};
</script>

<style scoped>
	.clearfix:before,
	.clearfix:after {
		display: table;
		content: "";
	}

	.clearfix:after {
		clear: both;
	}

	.searchDeviced {
		width: 12.5rem;
		margin-left: 0.3125rem;
	}

	.module-container {
		height: 100%;
	}

	.card {
		margin-top: 40px;
	}

	.item {
		padding: 30px 200px;
	}

	.center {
		margin: 20px 50px;
		font-size: 18px;
	}
</style>
