<template>
	<div class="con">
		<el-menu mode="horizontal" background-color="#3280fc" text-color="#fff">
		  <el-menu-item @click="goLogin()"><i class="el-icon-back" style="color: #fff !important;"></i>返回首页</el-menu-item>
		</el-menu>
		<div class="con-body">
			<h2>找回密码</h2>
			<div class="user-tips">请填写您的手机号/邮箱，验证成功后设置密码</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="right" label-width="90px">
				<el-form-item label="手机/邮箱" prop="phone">
					<el-input v-model="ruleForm.phone" placeholder="手机/邮箱" auto-complete="off"></el-input>
				</el-form-item>
				<el-row>
					<el-col :span="16">
						<el-form-item label="验证码" prop="region">
							<el-input v-model="ruleForm.region" placeholder="验证码" auto-complete="off"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8" style="padding-left: 20px;">
						<el-button v-if="showgetsms" class="w-100" type="primary" @click="getSms">获取验证码</el-button>
						<el-button v-if="!showgetsms" class="w-100" type="warning" plain>{{ countdown }}s之后重发</el-button>
					</el-col>
				</el-row>
				<el-form-item label="新密码" prop="pass">
					<el-input v-model="ruleForm.pass" placeholder="密码" auto-complete="off" show-password></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="checkPass">
					<el-input v-model="ruleForm.checkPass" placeholder="确认密码" auto-complete="off" show-password></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" class="w-100" @click="checkmima">重置密码</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="con-footer">
			© 2019 济南寸聚信息科技有限公司 | 鲁ICP备19005545号-1
		</div>
	</div>
</template>
<script>
	import user from '../../assets/api/user.js'
	export default {
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'))
				} else {
					if (this.ruleForm.checkPass !== '') {
						this.$refs.ruleForm.validateField('checkPass')
					}
					callback()
				}
			}
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'))
				} else if (value !== this.ruleForm.pass) {
					callback(new Error('两次输入密码不一致!'))
				} else {
					callback()
				}
			}
			return {
				showgetsms: true,
				countdown: 60,
				ruleForm: {
					phone: '',
					region: '',
					pass: '',
					checkPass: ''
				},
				rules: {
					// phone: [
					//   // {message: "请输入手机号或者邮箱"},
					// ],
					region: [{
						required: true,
						min: 6,
						max: 6,
						message: '长度为6个字符',
						trigger: 'blur'
					}],
					pass: [{
						required: true,
						validator: validatePass,
						trigger: 'blur'
					}],
					checkPass: [{
						required: true,
						validator: validatePass2,
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			async getSms() {
				const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
				const reg1 = /@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
				if (!reg1.test(this.ruleForm.phone) && !reg.test(this.ruleForm.phone)) {
					return this.$message.error('请填写正确的手机号或者邮箱')
				}
				if (reg.test(this.ruleForm.phone)) {
					const captchadata = {
						identifier: this.ruleForm.phone,
						issuer: 'WEB'
					}
					user.captcha(captchadata).then((res) => {
						if (res.code == 200) {
							this.daojishi()
							this.showgetsms = false
							this.$message.success('验证码获取成功！')
						}
					})
				} else {
					// 验证码
					const captchadata = {
						identifier: this.ruleForm.phone,
						issuer: 'WEB'
					}
					user.captcha(captchadata).then((res) => {
						if (res.code == 200) {
							this.daojishi()
							this.showgetsms = false
							this.$message.success('验证码获取成功！')
						}
					})
				}
			},

			 checkmima() {
				var myreg = /^[1][3,4,5,7,8][0-9]{9}$/
				if (myreg.test(this.ruleForm.phone)) {
					const singupdata = {
						identifier: this.ruleForm.phone,
						password: this.$md5(this.ruleForm.checkPass),
						captcha: this.ruleForm.region,
						issuer: 'WEB',
						type: 'MOBILE'
					}
					this.$refs.ruleForm.validate(valid => {
						if (!valid) return this.$message.error('请将信息填写完整')
						user.forget(singupdata).then((res) => {
							if (res.code == 200) {
								this.$message.success('密码修改成功')
								setTimeout(() => {
									this.$router.push('/login')
								}, 1000)
							}
						})
					})
				} else {
					const singupdata = {
						identifier: this.ruleForm.phone,
						password: this.$md5(this.ruleForm.checkPass),
						captcha: this.ruleForm.region,
						issuer: 'WEB',
						type: 'EMAIL'
					}
					this.$refs.ruleForm.validate(valid => {
						if (!valid) return this.$message.error('请将信息填写完整')

						user.forget(singupdata).then((res) => {
							if (res.code == 200) {
								this.$message.success('密码修改成功')
								setTimeout(() => {
									this.$router.push('/login')
								}, 1000)
							}
						})
					})
				}
			},
			daojishi() {
				if (this.countdown == 0) {
					this.countdown = 60
					this.showgetsms = true
					return
				}
				setTimeout(() => {
					this.daojishi()
				}, 1000)
				this.countdown--
			},
			goLogin(){
				this.$router.push('/Login')
			}
		}
	}
</script>
<style scoped>

	.con {
		width: 100%;
		height: 100%;
		background-color: #f2f2f2;
	}

	.con-body {
		padding-top: 70px !important;
		padding: 30px;
		background-color: #fff;
		border-radius: 8px;
		height: 530px;
		width: 600px;
		margin: auto;
		margin-top: 50px;
	}

	.con-body h2 {
		line-height: 45px;
		font-size: 24px;
		color: #333;
		text-align: center;
		margin-bottom: 30px;
	}

	.user-tips {
		font-size: 16px;
		color: #ffa46c;
		line-height: 32px;
		text-align: center;
		margin-bottom: 20px;
	}


	.con-footer {
		position: absolute;
		font-size: 14px;
		color: #666;
		bottom: 8px;
		left: 50%;
		transform: translate(-50%, -50%);
	}
</style>
