<template>
	<div>
		<Breadcrumb :first='bread[0]' :second="bread[1]"></Breadcrumb>
		<div style="width: 100%;display: flex;justify-content: center;">
			<el-card class="shadow-sm">
				<div class="info_all">
					<div>
						<img :src="form.icon" alt="" class="avatar">
						<el-upload ref="upload" action class="avatar-uploader" :data="aliyunOssToken" :show-file-list="false"
							:http-request="uploadImg">
							<el-button class="avatar-uploader-icon text-secondary" type="text">点击头像上传新头像
							</el-button>
						</el-upload>
					</div>
					<div>
						<label><i class="el-icon-user"></i> {{form.username}}</label>
						<el-button type="text" class="m-l" size="mini" @click="handlename('username')">修改</el-button>
					</div>
					<div>
						<label><i class="el-icon-phone-outline"></i> {{form.mobile}}</label>
						<el-button type="text" class="m-l" size="mini" @click="handlename('mobile')">修改</el-button>
					</div>
					<div>
						<label><i class="el-icon-message"></i> {{form.email}}</label>
						<el-button type="text" class="m-l" size="mini" @click="handlename('email')">修改</el-button>
					</div>
					<div>
						<label><i class="el-icon-key"></i> ***********</label>
						<el-button type="text" class="m-l" size="mini" @click="handlename('password')">修改</el-button>
					</div>
				</div>
			</el-card>
		</div>

		<el-dialog title="修改用户名" :visible.sync="usernameDialog" :append-to-body="true" width="400px">
			<el-form :model="edit" label-width="90px" :rules="rules" ref="username">
				<el-form-item label="用户名" prop="username">
					<el-input v-model="edit.username" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="usernameDialog = false">取 消</el-button>
				<el-button type="primary" @click="handleUsername('username')">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="修改手机号" :visible.sync="mobileDialog" width="400px">
			<el-form :model="edit" label-width="80px" :rules="rules" ref="mobile">
				<el-form-item label="手机号" prop="mobile">
					<el-input v-model="edit.mobile" autocomplete="off"></el-input>
				</el-form-item>
				<el-row>
					<el-col :span="16">
						<el-form-item label="验证码" prop="mobileCode">
							<el-input v-model="edit.mobileCode" autocomplete="off"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-button type="primary" class="w-100" @click="getSms('mobile')" v-if="showgetsms">获取验证码</el-button>
						<el-button v-else type="warning" class="w-100">{{ countdown }}s之后重发</el-button>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="mobileDialog = false">取 消</el-button>
				<el-button type="primary" @click="handleMobile('mobile')">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="修改邮箱" :visible.sync="emailDialog" width="400px">
			<el-form :model="edit" label-width="80px" :rules="rules" ref="email">
				<el-form-item label="邮箱号" prop="email">
					<el-input v-model="edit.email" autocomplete="off"></el-input>
				</el-form-item>

				<el-row>
					<el-col :span="16">
						<el-form-item label="验证码" prop="emailCode">
							<el-input v-model="edit.emailCode" autocomplete="off" class="mobileinput"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-button type="primary" class="w-100" @click="getSms('email')" v-if="showgetsms">获取验证码</el-button>
						<el-button v-else type="warning" class="w-100">{{ countdown }}s之后重发</el-button>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="emailDialog = false">取 消</el-button>
				<el-button type="primary" @click="handleEmail('email')">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="修改密码" :visible.sync="passwordDialog" width="500px">
			<el-form :model="edit" label-width="90px" :rules="rules" ref="password">
				<el-form-item label="原始密码" prop="oldPassword">
					<el-input type="password" v-model="edit.oldPassword" autocomplete="off" show-password>
					</el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="newPassword">
					<el-input type="password" v-model="edit.newPassword" autocomplete="off" show-password>
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="passwordDialog = false">取 消</el-button>
				<el-button type="primary" @click="handlePassword('password')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import axios from "axios";
	import moment from 'moment'
	import user from '../../assets/api/user.js'
	import other from '../../assets/api/other.js'
	import Breadcrumb from '../components/breadCrumb.vue'
	export default {
		components: {
			Breadcrumb
		},
		data() {
			return {
				bread: ['用户信息', ''],
				dialogNickName: false,
				dialogEmail: false,
				dialogPhone: false,
				dialogPassword: false,
				password: "*******",
				showgetsms: true,
				countdown: 60,
				form: {
					icon: require('../../assets/image/photo.png'),
					username:'',
					mobile:'',
					email:''
				},
				aliyunOssToken: {},
				default_img: '',
				mobileDialog: false,
				usernameDialog: false,
				emailDialog: false,
				passwordDialog: false,
				mobile: "",
				rules: {
					username: [{
						required: true,
						message: "请输入用户名",
						trigger: "blur"
					}],
					mobile: [{
						required: true,
						message: "请输入手机号",
						trigger: "blur"
					}],
					mobileCode: [{
						required: true,
						message: "请输入验证码",
						trigger: "blur"
					}],
					email: [{
						required: true,
						message: "请输入邮箱号",
						trigger: "blur"
					}],
					emailCode: [{
						required: true,
						message: "请输入验证码",
						trigger: "blur"
					}],
					oldPassword: [{
						required: true,
						message: "请输入原始密码",
						trigger: "blur"
					}],
					newPassword: [{
						required: true,
						message: "请输入新密码",
						trigger: "blur"
					}]
				},
				edit: {
					username: "",
					mobile: "",
					email: "",
					emailCode: "",
					mobileCode: "",
					oldPassword: "",
					newPassword: ""
				}
			}
		},
		mounted() {
			this.getUserData()
		},
		methods: {
			//获取用户信息
			async getUserData() {
				await user.getUserData().then(res => {
					if (res.code === 200) {
						this.form = res.data
					} else {
						this.$message.error('获取用户信息失败')
						return
					}
				}, () => {
					this.$message.error('获取用户信息失败')
					return
				})
			},
			// 获取验证码
			async getSms(value) {
				let captchadata = {};
				if (value == "mobile") {
					const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
					if (!reg.test(this.edit.mobile))
						return this.$message.error("请填写正确手机号");
					captchadata = {
						identifier: this.edit.mobile,
						issuer: "WEB"
					};
				} else {
					const reg2 = /@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
					if (!reg2.test(this.edit.email))
						return this.$message.error("请填写正确邮箱号");
					captchadata = {
						identifier: this.edit.email,
						issuer: "WEB"
					};
				}

				await user.captcha(captchadata).then(res => {
					if(res.code===200){
						this.daojishi();
						this.showgetsms = false;
						this.$message.success("验证码获取成功！");
					}
					else{
						this.$message.error("验证码获取失败！");
						return
					}
				});
			},

			// 倒计时
			daojishi() {
				if (this.countdown == 0) {
					this.countdown = 60;
					this.showgetsms = true;
					return;
				}
				setTimeout(() => {
					this.daojishi();
				}, 1000);
				this.countdown--;
			},

			//上传图片
			handleOssList(file, filelist) {
				this.$message.error("上传失败,超出上传数量最大限制");
			},
			uploadImg(file) {
				var _self = this;
				let imgType = file.file.type.split("/")[1].toLowerCase();
				if (imgType != "jpg" && imgType != "png" && imgType != "jpeg") {
					this.$message.error("请上传正确的图片类型");
					return;
				}
				other.getOSSToken()
					.then(function(res) {
						_self.aliyunOssToken = res.data;
						let filename = file.file.name; //md5对图片名称进行加密
						let keyValue = `${_self.aliyunOssToken.dir}/` + filename;

						// 组装formdata
						let formdata = new FormData();
						formdata.append("name", file.file.name);
						formdata.append("key", keyValue);
						formdata.append("policy", _self.aliyunOssToken.policy);
						formdata.append("OSSAccessKeyId", _self.aliyunOssToken.accessId);
						formdata.append("success_action_status", 200);
						formdata.append("signature", _self.aliyunOssToken.signature);
						formdata.append("file", file.file);

						_self
							.uploadOSS(formdata, _self.aliyunOssToken.host)
							.then(function(res) {
								let img = _self.aliyunOssToken.host + "/" + keyValue;
								_self.form.icon = _self.aliyunOssToken.host + "/" + keyValue;
								user.patchName({icon: img}).then(res=>{
									if(res.code===200){
										_self.$message.success("上传成功");
										this.$refs.upload.clearFiles();
									}
									else{
										_self.$message.error("上传失败");
										this.$refs.upload.clearFiles();
									}
								},()=>{
									_self.$message.error("上传失败");
									this.$refs.upload.clearFiles();
								})
							})
							.catch(function(error) {
								_self.$message.error("上传失败");
							});
					})
					.catch(function(error) {});
			},
			uploadOSS(formData, url) {
				const config = {
					headers: {
						"Content-Type": "multipart/form-data;boundary=" + new Date().getTime()
					}
				};
				return axios.post(url, formData, config);
			},
			//获取数据到弹窗
			handlename(formName) {
				switch (formName) {
					case "username":
						let username = this.form.username;
						this.edit.username = username;
						this.usernameDialog = true;
						break;
					case "mobile":
						let mobile = this.form.mobile;
						this.edit.mobile = mobile;
						this.mobileDialog = true;
						break;
					case "email":
						let email = this.form.email;
						this.edit.email = email;
						this.emailDialog = true;
						break;
					case "password":
						this.passwordDialog = true;
						break;

					default:
						break;
				}
			},

			handleUsername(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.usernameDialog = false;
						let Data = {
							username: this.edit.username
						};
						user.patchName(Data).then(res => {
							this.$message({
								message: "修改成功",
								type: "success"
							});
							this.getUserData();
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},

			handleMobile(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let Data = {
							mobile: this.edit.mobile,
							captcha: this.edit.mobileCode,
							issuer: "WEB"
						};
						user.putMobile(Data).then(res => {
							if(res.code===200){
								this.$message({
									message: "修改成功",
									type: "success"
								});
						this.mobileDialog = false;
								this.getUserData();
							}
						});
					} else {
						console.log("验证码不正确");
						return false;
					}
				});
			},

			async handleEmail(formName) {
				await this.$refs[formName].validate(valid => {
					if (valid) {
						let Data = {
							email: this.edit.email,
							captcha: this.edit.emailCode,
							issuer: "WEB"
						};
						user.putEmail(Data).then(res => {
							if(res.code===200){
								this.emailDialog = false;
								this.$message.success('修改成功')
								this.getUserData();
							}
							else{
								this.$message.error('修改失败')
								return
							}
						},()=>{
							this.$message.error('修改失败')
							return
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},

			async handlePassword(formName) {
				await this.$refs[formName].validate(valid => {
					if (valid) {
						let Data = {
							newPassword: this.$md5(this.edit.newPassword),
							oldPassword: this.$md5(this.edit.oldPassword)
						};
						user.putPassword(Data).then(res => {
							if(res.code===200){
								this.passwordDialog = false;
								this.$message.success('修改成功')
								// 修改成功 重新登录
								this.$router.push(`/login`);
							}
							else{
								this.$message.error('修改失败')
								return
							}
						},()=>{
							this.$message.error('修改失败')
							return
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	.el-card {
		width: 450px !important;
		padding: 90px 0px;
	}

	.m-l {
		margin-left: 10px !important;
	}

	.info_all {
		display: flex;
		flex-direction: column;

		div {
			text-align: center;
			width: 100%;
			padding: 10px 0px;

			label {
				i {
					color: #bbb !important;
					font-size: 15px;
					margin-right: 10px;
				}
			}
		}

		div:nth-child(1) {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0px !important;

			.avatar {
				border: 1xp solid #e5e5e5;
				height: 100px;
				width: 100px;
				border-radius: 500px;
			}

			small {
				margin-top: 10px;
			}
		}

		div:nth-child(2) {
			padding-top: 30px;
		}
	}
</style>
