<template>
  <div>
    <Breadcrumb :first="bread[0]" :second="bread[1]"></Breadcrumb>
    <el-row>
      <el-col>
        <el-card class="shadow-sm">
          <el-row>
            <el-col>
              <h5>分组</h5>
            </el-col>
            <el-col class="content_margin_top">
              <div
                style="display: flex;justify-content:flex-start;flex-wrap: wrap;"
              >
                <el-button
                  plain
                  type="primary"
                  :class="
                    activeBtn === index ? 'btn-group btn-active' : 'btn-group'
                  "
                  v-for="(item, index) in groupList"
                  size="mini"
                  @click="getdeviceByGroup(item.id, index)"
                >
                  {{ item.name }}
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="13">
        <el-card class="shadow-sm">
          <el-row>
            <el-col>
              <h5>设备</h5>
            </el-col>
            <el-col class="content_margin_top">
              <el-table
                ref="tableDevice"
                :data="$store.state.tableDataDevice"
                size="mini"
                stripe
                border
                :header-cell-style="{
                  background: '#f1f1f1',
                  'text-align': 'center',
                }"
                :cell-style="{ 'text-align': 'center' }"
                @row-click="rowClick"
                :height="tableHeight"
              >
                <el-table-column prop="id" label="设备id" width="70px">
                </el-table-column>
                <el-table-column prop="name" label="设备名称">
                </el-table-column>
                <el-table-column label="设备状态" width="90px">
                  <template slot-scope="scope">
                    <el-tag
                      type="success"
                      size="mini"
                      v-if="scope.row.online == true"
                      >在线</el-tag
                    >
                    <el-tag
                      type="danger"
                      size="mini"
                      v-if="scope.row.online == false"
                      >离线</el-tag
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="11">
        <el-card class="shadow-sm">
          <el-row>
            <el-col>
              <h5>资源</h5>
            </el-col>
            <el-col class="content_margin_top">
              <el-table
                ref="tableResource"
                :data="$store.state.tableDataResource"
                size="mini"
                stripe
                border
                :header-cell-style="{
                  background: '#f1f1f1',
                  'text-align': 'center',
                }"
                :cell-style="{ 'text-align': 'center' }"
                :height="tableHeight"
              >
                <el-table-column
                  prop="addr"
                  label="地址"
                  width="100px"
                ></el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column label="开关" width="120px">
                  <template slot-scope="scope">
                    <!-- <el-switch
                      v-if="scope.row.type == 'Bool'"
                      :disabled="!(deviceOnline && scope.row.rw)"
                      v-model="scope.row.show"
                      :active-value="true"
                      :inactive-value="false"
                      active-color="#13ce66"
                      @change="updateResourceStatus(scope.row)"
                      @click.stop.native
                    ></el-switch>
                    <el-select
                      style="width:100%"
                      size="small"
                      v-else-if="scope.row.type == 'Enum'"
                      v-model="scope.row.value"
                      placeholder="请选择"
                      :disabled="!(deviceOnline && scope.row.rw)"
                      @change="open(scope.row.addr, scope.row.value, scope.row.type, scope.row.items)"
                    >
                      <el-option v-for="item in scope.row.items" :key="item.k" :value="item.k" :label="item.v"></el-option>
                    </el-select>
                    <el-button
                      v-else
                      type="primary"
                      plain
                      size="mini"
                      :disabled="!(deviceOnline && scope.row.rw)"
                      @click="changeValue($store.state.deviceid, scope.row)"
                    >
                      {{ scope.row | formatAnalog }}
                      {{ scope.row.unit }}
                    </el-button> -->

                    <el-switch
                      v-model="scope.row.show"
                      :disabled="!owner"
                      :active-value="true"
                      :inactive-value="false"
                      active-color="#13ce66"
                      @change="updateResourceStatus(scope.row)"
                      @click.stop.native
                    ></el-switch>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
import device from "../../assets/api/device.js";
import Breadcrumb from "../components/breadCrumb.vue";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      tableHeight: "",
      activeBtn: "",
      childDeviceConf: [],
      deviceOnline: "",
      deviceRow: {},
      conf: "",
      dev: "",
      groupid: "",
      deviceid: "",
      groupList: [],
      bread: ["设备列表", ""],
      tableData: [],
      tableDataResource: [],
      editValueForm: [],
      owner: false,
    };
  },
  filters: {
    formatAnalog(item) {
      if (item.type != "Float32") {
        return item.value;
      }
      var val = item.value;
      if (val == 0) {
        val = "0";
      }
      if (val) {
        val = val.toString().replace(/\$|\,/g, "");
        const sign = val == (val = Math.abs(val));
        val = Math.floor(val * 100 + 0.50000000001);
        let cents = val % 100;
        val = Math.floor(val / 100).toString();
        if (cents < 10) {
          cents = "0" + cents;
        }
        for (var i = 0; i < Math.floor((val.length - (1 + i)) / 3); i++) {
          val =
            val.substring(0, val.length - (4 * i + 3)) +
            "," +
            val.substring(val.length - (4 * i + 3));
        }
        return (sign ? "" : "-") + val + "." + cents;
      }
    },
  },
  mounted() {
    this.getdeviceGroup();

    this.$nextTick(function() {
      this.tableHeight =
        window.innerHeight - this.$refs.tableDevice.$el.offsetTop - 300;

      // 监听窗口大小变化
      let self = this;
      window.onresize = function() {
        self.tableHeight =
          window.innerHeight - self.$refs.tableDevice.$el.offsetTop - 300;
      };
    });
    //this.$refs.table.$el.offsetTop：表格距离浏览器的高度
  },
  methods: {
    rowClick(row) {
      this.deviceOnline = row.online;
      this.owner = row.permissions.owner;
      this.getresourceByDevice(row, row.id);
    },
    //获取设备分组
    async getdeviceGroup() {
      await device.getdeviceGroup().then(
        (res) => {
          if (res.code === 200) {
            this.groupList = res.data;
          } else {
            this.$message.error("获取设备分组失败");
            return;
          }
        },
        () => {
          this.$message.error("获取设备分组失败");
          return;
        }
      );
    },
    //通过分组获取设备信息
    async getdeviceByGroup(id, index) {
      this.activeBtn = index;
      this.groupid = id;
      this.$store.state.groupid = id;
      this.$store.state.tableDataResource = [];
      this.deviceid = "";
      this.$store.state.deviceRow = {
        permissions: {
          conf: false,
        },
      };
      this.deviceOnline = "";
      await device
        .getdeviceByGroup({
          groupId: id,
        })
        .then(
          (res) => {
            if (res.code !== 200) {
              this.$message.error("获取设备数据失败");
              return;
            } else {
              this.$store.state.tableDataDevice = res.data;
            }
          },
          () => {
            this.$message.error("建立连接失败");
          }
        );
    },
    //获通过设备id获取资源
    async getresourceByDevice(row, id) {
      this.$store.state.deviceRow = row;
      this.$store.state.deviceid = id;
      this.deviceid = id;
      await device
        .getresourceByDevice({
          deviceId: id,
        })
        .then(
          (res) => {
            if (res.code === 200) {
              this.$store.state.deviceid = id;
              let data = JSON.parse(JSON.stringify(res.data));
              this.tableDataResource = [];
              //检测每个资源的show字段，并且修改bool型的value值
              if (data.length != 0) {
                data.forEach((v) => {
                  //   if (v.hasOwnProperty('show') || v.show) {
                  //     if (v.value === 'true') {
                  //       v.value = true
                  //     } else if (v.value === 'false') {
                  //       v.value = false
                  //     }
                  //     this.tableDataResource.push(v)
                  //   }
                  this.tableDataResource.push(v);
                });
              }

              this.$store.state.tableDataResource = this.tableDataResource;
            } else {
              this.$message.error("获取资源数据失败");
              return;
            }
          },
          () => {
            this.$message.error("获取资源数据失败");
            return;
          }
        );
    },
    //修改资源状态
    async updateResourceStatus(info) {
      let params = {
        deviceId: this.deviceid,
        showList: [
          {
            regAddr: info.addr,
            show: info.show,
          },
        ],
      };
      await device.updateRegShow(params).then(
        (res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.getresourceByDevice(
              this.$store.state.deviceRow,
              this.deviceid
            );
          } else {
            this.$message.error("操作失败");
            return;
          }
        },
        () => {
          this.$message.error("操作失败");
          return;
        }
      );
    },
    open(addr, value, data, items) {
      this.$confirm("确定修改此数据", "修改数据", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(async () => {
        for (var i in items) {
          if (items[i] === value) {
            value = i;
          }
        }
        const body = {
          deviceId: this.deviceid,
          regs: [
            {
              addr: addr,
              type: data,
              value: value,
            },
          ],
        };
        device
          .getDeviceWriteReg(body)
          .then((res) => {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消修改",
            });
          });
      });
    },
    changeValue(id, item) {
      this.id = id;
      this.ValueDialogVisible = true;
      this.editValueForm = {
        id: id,
        ...item,
      };
    },
  },
  destroyed() {
    let state = this.$store.state;
    state.tableDataDevice = [];
    state.newListDevice = [];
    state.tableDataResource = [];
    state.deviceid = "";
    state.groupid = "";
  },
};
</script>

<style>
.btn-active {
  background-color: #3280fc !important;
  color: #fff !important;
}

.btn-group {
  margin-bottom: 10px !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
  width: 128px;
  text-overflow: ellipsis !important;
  overflow: hidden;
}

.el-card__body {
  padding-bottom: 10px !important;
}

.childDeviceConf {
  margin-bottom: 10px;
  background-color: #efefef;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.childDeviceConf > span {
  width: 140px;
}

.childDeviceConf .el-input {
  width: 170px;
}

.childDeviceConf .el-select .el-input {
  width: 170px;
}
</style>
